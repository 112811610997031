import React, { ReactNode } from 'react'
import { makeStyles } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'

interface Props {
    facts: Array<{ label: string; node: ReactNode }>
}

const useStyles = makeStyles({
    facts: {
        listStyle: 'none',
        color: grey[600],
        fontSize: 14,
        margin: 0,
        paddingLeft: 0,
    },
    fact: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 6,
    },
    factLabel: {
        display: 'inline-block',
        width: 120,
    },
    factValue: {
        display: 'inline-block',
        flex: 1,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
})

interface FactProps {
    label: string
    classes: Record<any, any>
}

const Fact: React.FC<FactProps> = ({ classes, label, children }) => (
    <li className={classes.fact}>
        <span className={classes.factLabel}>{label}</span>
        <span className={classes.factValue}>{children}</span>
    </li>
)

export default function Facts(props: Props) {
    const classes = useStyles()

    return (
        <ul className={classes.facts}>
            {props.facts.map(fact => (
                <Fact label={fact.label} classes={classes}>
                    {fact.node}
                </Fact>
            ))}
        </ul>
    )
}
