import Stage from './Stage'

export enum ExecutionStatus {
    SUCCESS = 'SUCCESS',
    IN_PROGRESS = 'IN_PROGRESS',
    FAILED = 'FAILED',
    NOT_EXECUTED = 'NOT_EXECUTED',
    ABORTED = 'ABORTED',
    UNSTABLE = 'UNSTABLE'
}

export default interface Execution {
    name: string
    status: ExecutionStatus
    startTimeMillis: number
    endTimeMillis: number
    durationMillis: number
    stages: Array<Stage>
}
