import Pipeline from '../../../currentworkspace/data/entities/Pipeline'

export enum ToolStatus {
    NOT_STARTED = 'NOT_STARTED',
    IN_PROGRESS = 'IN_PROGRESS',
    READY = 'READY',
    ERROR = 'ERROR',
}

export default interface Tool {
    name: string
    image: string
    siteUrl: string | null
    jenkinsUrl: string | null
    containerPort: string | null
    publicPort: string | null
    pipelines: Array<Pipeline>
    status: ToolStatus
}
