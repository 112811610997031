import * as React from 'react'
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    TextField,
    Typography,
} from '@material-ui/core'
import { useState, useEffect } from 'react'
import { ChangeEvent } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Pipeline from '../../data/entities/Pipeline'
import BuildParameter from '../../data/entities/BuildParameter'
import splitVersioncode from '../../../common/data/splitVersioncode'

interface Props {
    isOpen: boolean
    isBusy: boolean
    pipeline?: Pipeline
    lastVersion: string
    onCloseRequest: () => void
    onConfirm: (parameters: BuildParameter) => void
}

interface State {
    major: string
    minor: string
    fix: string
}

const DEFAULT_STATE = {
    major: '0',
    minor: '0',
    fix: '1'
}

const useStyles = makeStyles({
    progress: {
        marginRight: 10,
    },
    formControl: {
        marginBottom: 16,
        marginRight: 16,
        maxWidth: 120,
    },
})

function BuildParameterDialog({ isOpen, isBusy, lastVersion, onConfirm, onCloseRequest }: Props) {
    const [state, setState] = useState<State>({ ...DEFAULT_STATE })
    const classes = useStyles()
    useEffect(() => {
        const splitVersion = splitVersioncode(lastVersion);

        setState({
            major: splitVersion.MAJOR,
            minor: splitVersion.MINOR,
            fix: splitVersion.FIX
        });
    }, [lastVersion]);


    const onMajorChange = (event: ChangeEvent<any>) => {
        setState({ ...state, major: event.currentTarget.value })
    }

    const onMinorChange = (event: ChangeEvent<any>) => {
        setState({ ...state, minor: event.currentTarget.value })
    }

    const onFixChange = (event: ChangeEvent<any>) => {
        setState({ ...state, fix: event.currentTarget.value })
    }

    const shouldDisableButton = !state.major || !state.minor || !state.fix || isBusy

    return (
        <Dialog open={isOpen} onClose={onCloseRequest} fullWidth={true}>
            <DialogTitle>Build artifact with new version</DialogTitle>
            <DialogContent>
                <Typography>Latest build: {lastVersion}</Typography>

                <FormControl fullWidth={false} className={classes.formControl}>
                    <TextField
                        autoFocus
                        margin="dense"
                        value={state.major}
                        id="major"
                        label="Major"
                        type="number"
                        autoComplete="off"
                        InputProps={{ inputProps: { min: 0, max: 99 } }}
                        onChange={onMajorChange}
                        disabled={isBusy}
                    />
                </FormControl>

                <FormControl fullWidth={false} className={classes.formControl}>
                    <TextField
                        margin="dense"
                        value={state.minor}
                        id="minor"
                        label="Minor"
                        type="number"
                        autoComplete="off"
                        InputProps={{ inputProps: { min: 0, max: 99 } }}
                        onChange={onMinorChange}
                        disabled={isBusy}
                    />
                </FormControl>


                <FormControl fullWidth={false} className={classes.formControl}>
                    <TextField
                        margin="dense"
                        value={state.fix}
                        id="fix"
                        label="Fix"
                        type="number"
                        autoComplete="off"
                        InputProps={{ inputProps: { min: 0, max: 99 } }}
                        onChange={onFixChange}
                        disabled={isBusy}
                    />
                </FormControl>

            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseRequest}>Cancel</Button>
                <Button
                    onClick={() => onConfirm({
                        MAJOR: state.major,
                        MINOR: state.minor,
                        FIX: state.fix,
                    })}
                    color="primary"
                    disabled={shouldDisableButton}
                >
                    <CircularProgress
                        className={classes.progress}
                        style={{ opacity: isBusy ? 1 : 0 }}
                        size={16}
                    />
                    Build
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default BuildParameterDialog
