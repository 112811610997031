import * as React from 'react'
import { green, grey, red } from '@material-ui/core/colors'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import WarningIcon from '@material-ui/icons/Warning'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Execution, { ExecutionStatus } from '../../data/entities/Execution'
import Avatar from '@material-ui/core/Avatar/Avatar'
import { CircularProgress, Grid, Typography } from '@material-ui/core'
import { formatDateTime } from '../../../common/presentation/formatDateTime'
import { formatDurationToMinutes } from '../../../common/presentation/formatDurationToMinutes'
import ExecutionStages from './ExecutionStages'
import PastExecutions from './PastExecutions'

interface Props {
    execution: Execution
}

const AVATAR_BASE_STYLE = {
    marginRight: 16,
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },

    detailsWrapper: {
        flex: 1,
    },

    errorMarker: {
        ...AVATAR_BASE_STYLE,
        backgroundColor: red[500],
    },
    successMarker: {
        ...AVATAR_BASE_STYLE,
        backgroundColor: green[500],
    },
	abortedMarker: {
        ...AVATAR_BASE_STYLE,
        backgroundColor: grey[500],		
	},
    pendingMarker: {
        ...AVATAR_BASE_STYLE,
        background: 'transparent',
    },
    detailText: {
        color: grey[500],
    },
}))

interface ExecutionStatusAvatarProps {
    execution: Execution
    classes: Record<any, any>
}

const TITLES_BY_STATUS = {
    [ExecutionStatus.SUCCESS]: 'Deployment successful',
    [ExecutionStatus.IN_PROGRESS]: 'Deployment in progress ...',
    [ExecutionStatus.NOT_EXECUTED]: 'Deployment idle',
    [ExecutionStatus.FAILED]: 'Deployment failed',
    [ExecutionStatus.ABORTED]: 'Deployment aborted',
    [ExecutionStatus.UNSTABLE]: 'Deployment unstable',
}

const ExecutionStatusAvatar = ({ execution, classes }: ExecutionStatusAvatarProps) => {
    const avatarClassName = classes[CLASS_NAMES_BY_STATUS[execution.status]]
    const Icon = ICON_COMPONENTS_NAMES_BY_STATUS[execution.status]
    return (
        <Avatar className={avatarClassName}>
            <Icon />
        </Avatar>
    )
}

const CLASS_NAMES_BY_STATUS = {
    SUCCESS: 'successMarker',
    NOT_EXECUTED: 'pendingMarker',
    ABORTED: 'abortedMarker',
    IN_PROGRESS: 'pendingMarker',
    FAILED: 'errorMarker',
    UNSTABLE: 'warnMarker',
}

const ICON_COMPONENTS_NAMES_BY_STATUS = {
    SUCCESS: CheckIcon,
    FAILED: CloseIcon,
    NOT_EXECUTED: CircularProgress,
    ABORTED: CloseIcon,
    IN_PROGRESS: CircularProgress,
    UNSTABLE: WarningIcon,
}

function DeploymentResult({ execution }: Props) {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <ExecutionStatusAvatar execution={execution} classes={classes} />
            <div className={classes.detailsWrapper}>
                <Typography variant="body2" style={{ lineHeight: '38px' }}>
                    {TITLES_BY_STATUS[execution.status]}
                </Typography>
            </div>
        </div>
    )
}

export default DeploymentResult
