import * as React from 'react'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Step,
    StepButton,
    Stepper,
    Typography,
} from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import ArtifactsSelector from './ArtifactsSelector'
import ToolsSelector from './ToolsSelector'
import EnvironmentsSelector from './EnvironmentsSelector'
import hintStyle from './hintStyle'
import BasicsInput from './BasicsInput'
import Artifact from '../../../../currentworkspace/data/entities/Artifact'
import WorkspaceDraft from './WorkspaceDraft'
import Workspace from '../../../data/entities/Workspace'
import Tool from '../../../data/entities/Tool'

interface Props {
    isOpen: boolean
    isBusy: boolean
    artifacts: Array<Artifact>
    workspace: Workspace | WorkspaceDraft | null
    onCloseRequest: () => void
    onConfirm: (workspace: WorkspaceDraft) => void
}

interface State {
    name: string
    activeStep: number
    selectedWorkspace: WorkspaceDraft
}

const DEFAULT_STATE = {
    name: '',
    activeStep: 0,
    selectedWorkspace: {},
}

const useStyles = makeStyles({
    content: {
        height: 680,
    },
    stepper: {
        padding: '24px 8px',
    },
    progress: {
        marginRight: 10,
    },
    hint: hintStyle,
})

const STEP_TITLES = ['Basics', 'Artifacts', 'Tools', 'Environments']

const STEP_VALIDATORS: Array<(state: State) => boolean> = [
    ({ selectedWorkspace }) => selectedWorkspace && selectedWorkspace.name.trim().length > 0,
    () => true,
    () => true,
    () => true, // TODO check if at least one environment available
]

const createArtifactEnvironmentConfigWithLatestTags = (artifacts: Array<Artifact>) => {
    const result: { [key: string]: string | null } = {}
    artifacts.forEach(artifact => {
        const releases = artifact.releases
        const releaseCount = releases.length
        result[artifact.id] = releaseCount > 0 ? releases[releaseCount - 1].versionCode : null
    })

    return result
}

const createNewWorkspaceDraft = (artifacts: Array<Artifact>) => ({
    name: '',
    artifactIds: [],
    tools: [],
    environments: [
        {
            name: 'Test (default)',
            artifactReleasesByArtifactIds: createArtifactEnvironmentConfigWithLatestTags(artifacts),
            artifactDatasetByArtifactIds: {}
        },
    ],
})

const renderStepContent = (
    classes: Record<any, any>,
    props: Props,
    state: State,
    setState: Dispatch<SetStateAction<State>>
) => {
    const onNameChange = (name: string) =>
        setState(prevState => ({
            ...prevState,
            selectedWorkspace: {
                ...prevState.selectedWorkspace,
                name,
            },
        }))

    const onToolsChange = (tools: Array<Tool>) => {
        setState(prevState => ({
            ...prevState,
            selectedWorkspace: {
                ...prevState.selectedWorkspace,
                tools,
            },
        }))
    }

    const onArtifactsSelectionChange = (newSelectedArtifactIds: Array<string>) => {
        setState(prevState => ({
            ...prevState,
            selectedWorkspace: {
                ...prevState.selectedWorkspace,
                artifactIds: newSelectedArtifactIds,
            },
        }))
    }

    const { isBusy } = props
    const { activeStep } = state

    // TODO block UI when isBusy
    switch (activeStep) {
        case 0:
            return (
                <BasicsInput
                    name={state.selectedWorkspace.name}
                    onNameChange={onNameChange}
                    disabled={isBusy}
                />
            )
        case 1:
            return (
                <ArtifactsSelector
                    artifacts={props.artifacts}
                    selectedArtifactIds={state.selectedWorkspace.artifactIds}
                    onSelectionChange={onArtifactsSelectionChange}
                />
            )
        case 2:
            return (
                <>
                    <Typography variant="body2" className={classes.hint}>
                        Third-party tools may be added to the workspace on demand.
                    </Typography>

                    <ToolsSelector tools={state.selectedWorkspace.tools} onChange={onToolsChange} />
                </>
            )
        case 3:
            return (
                <EnvironmentsSelector
                    artifacts={props.artifacts}
                    environments={state.selectedWorkspace.environments}
                    onChange={environments =>
                        setState(prevState => ({
                            ...prevState,
                            selectedWorkspace: {
                                ...prevState.selectedWorkspace,
                                environments,
                            },
                        }))
                    }
                />
            )
        default:
            throw Error('No content available for given step: ' + activeStep)
    }
}

function AddOrEditWorkspaceDialog(props: Props) {
    const { isOpen, isBusy, artifacts, workspace, onConfirm, onCloseRequest } = props
    const [state, setState] = useState<State>({
        ...DEFAULT_STATE,
        selectedWorkspace: workspace || createNewWorkspaceDraft(artifacts),
    })

    useEffect(() => {
        if (props.workspace !== null) {
            setState(prevState => ({ ...prevState, selectedWorkspace: props.workspace! }))
        }
    }, [props.workspace])

    const classes = useStyles()

    const steps = STEP_TITLES
    const { activeStep } = state

    function onNext() {
        if (activeStep === STEP_TITLES.length - 1) {
            if (workspace) {
                alert('Stay tuned - editing will be added soon') // TODO
                return
            }

            onConfirm(state.selectedWorkspace)
            return
        }

        setState(prevState => ({ ...prevState, activeStep: prevState.activeStep + 1 }))
    }

    function onBack() {
        setState(prevState => ({ ...prevState, activeStep: prevState.activeStep - 1 }))
    }

    const isFinalStep = activeStep === steps.length - 1
    const canProceed = STEP_VALIDATORS[activeStep](state) && !isBusy

    return (
        <Dialog open={isOpen} onClose={onCloseRequest} fullWidth>
            <DialogTitle>{workspace ? 'Edit' : 'Add'} workspace</DialogTitle>
            <DialogContent className={classes.content}>
                <Stepper activeStep={activeStep} nonLinear className={classes.stepper}>
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepButton onClick={() => setState({ ...state, activeStep: index })}>
                                {label}
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>

                {renderStepContent(classes, props, state, setState)}
            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseRequest}>Cancel</Button>
                <Button disabled={activeStep === 0} onClick={onBack}>
                    Back
                </Button>
                <Button variant="contained" color="primary" onClick={onNext} disabled={!canProceed}>
                    {isFinalStep && isBusy && (
                        <CircularProgress className={classes.progress} size={16} />
                    )}

                    {isFinalStep ? 'Finish' : 'Next'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddOrEditWorkspaceDialog
