import Artifact from './entities/Artifact'
import { TokenStorage } from '../../signin/data/TokenStorage'
import handleAuthError from '../../common/data/handleAuthError'
import Pipeline from './entities/Pipeline'
import createRequestHeader from '../../common/data/createRequestHeader'
import Workspace from '../../workspaces/data/entities/Workspace'
import ArtifactDraft from './entities/ArtifactDraft'
import BuildParameter from './entities/BuildParameter'
import Tool from '../../workspaces/data/entities/Tool'
import Environment from '../../workspaces/data/entities/Environment'

export default class ArtifactsApi {
    private baseUrl: string
    private tokenStorage: TokenStorage

    constructor(baseUrl: string, tokenStorage: TokenStorage) {
        this.baseUrl = baseUrl
        this.tokenStorage = tokenStorage
    }

    fetchAll(): Promise<Array<Artifact>> {
        return fetch(`${this.baseUrl}/artifacts`, {
            headers: createRequestHeader(this.tokenStorage),
        })
            .then(handleAuthError(this.tokenStorage))
            .then(response => response.text())
            .then(data => JSON.parse(data))
    }

    fetchAllInWorkspace(workspace: Workspace): Promise<Array<Artifact>> {
        return fetch(`${this.baseUrl}/artifacts?workspaceId=${workspace.id}&includePipelines`, {
            headers: createRequestHeader(this.tokenStorage),
        })
            .then(handleAuthError(this.tokenStorage))
            .then(response => response.text())
            .then(data => JSON.parse(data))
    }

    fetchAllEventsInArtifacts(workspace: Workspace): Promise<Array<Artifact>> {

        const controller = new AbortController();
        const id = setTimeout(() => controller.abort(), 8500);

        return fetch(`${this.baseUrl}/artifacts/events?workspaceId=${workspace.id}`, {
            headers: createRequestHeader(this.tokenStorage),
            signal: controller.signal,
        })
            .then(handleAuthError(this.tokenStorage))
            .then(response => {
                clearTimeout(id)
                return response
            })
            .then(response => response.text())
            .then(data => JSON.parse(data))
    }

    triggerBuild(artifact: Artifact, pipeline: Pipeline, parameters?: BuildParameter) {
        let body:any = {
            artifactId: artifact.id,
            pipelineName: pipeline.name
        }
        if (parameters) { body.parameters = parameters; }

        return fetch(this.baseUrl + '/artifacts/builds', {
            method: 'POST',
            headers: createRequestHeader(this.tokenStorage),
            body: JSON.stringify(body),
        })
            .then(handleAuthError(this.tokenStorage))
            .then(response => response.text())
            .then(data => JSON.parse(data))
    }

    // TODO move to own module ToolsApi
    triggerToolBuild(workspace: Workspace, tool: Tool, pipeline: Pipeline) {
        return fetch(this.baseUrl + '/artifacts/tool-builds', {
            method: 'POST',
            headers: createRequestHeader(this.tokenStorage),
            body: JSON.stringify({
                workspaceId: workspace.id,
                toolName: tool.name,
                pipelineName: pipeline.name,
            }),
        })
            .then(handleAuthError(this.tokenStorage))
            .then(response => response.text())
            .then(data => JSON.parse(data))
    }

    // TODO move to own module ToolsApi
    triggerEnvironmentDeployment(workspace: Workspace, environment: Environment) {
        return fetch(this.baseUrl + '/artifacts/environment-deployments', {
            method: 'POST',
            headers: createRequestHeader(this.tokenStorage),
            body: JSON.stringify({
                workspaceId: workspace.id,
                environment,
            }),
        })
            .then(handleAuthError(this.tokenStorage))
            .then(response => response.text())
            .then(data => JSON.parse(data))
    }


    createNew(draft: ArtifactDraft): Promise<Artifact> {
        return fetch(this.baseUrl + '/artifacts', {
            method: 'POST',
            headers: createRequestHeader(this.tokenStorage),
            body: JSON.stringify(draft),
        })
            .then(handleAuthError(this.tokenStorage))
            .then(response => response.text())
            .then(data => JSON.parse(data))
    }
}
