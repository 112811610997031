import * as React from 'react'
import { useState } from 'react'
import {
    Avatar,
    Fab,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles,
    Typography,
} from '@material-ui/core'
import { blue } from '@material-ui/core/colors'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import AddOrEditToolDialog from './AddOrEditToolDialog'
import Tool from '../../../data/entities/Tool'

interface Props {
    onChange: (tools: Array<Tool>) => void
    tools: Array<Tool>
}

interface State {
    isToolDialogOpen: boolean
}

const DEFAULT_STATE: State = {
    isToolDialogOpen: false,
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    avatar: {
        backgroundColor: blue[500],
    },
}))

function ToolsSelector(props: Props) {
    const classes = useStyles()
    const [state, setState] = useState({ ...DEFAULT_STATE })

    const onNewToolAddRequest = (tool: Tool) => {
        const tools = [...props.tools]
        tools.push(tool)
        setState({ isToolDialogOpen: false })
        props.onChange(tools)
    }

    const onDeleteToolRequest = (toolIndex: number) => {
        const tools = [...props.tools]
        tools.splice(toolIndex, 1)
        setState({ ...state })
        props.onChange(tools)
    }

    return (
        <>
            {!props.tools.length && <Typography variant="body2">No tools created yet.</Typography>}

            <List dense className={classes.root}>
                {props.tools.map((tool, index) => {
                    return (
                        <ListItem key={tool.name} button>
                            <ListItemAvatar>
                                <Avatar className={classes.avatar}>
                                    {tool.name[0].toUpperCase()}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={tool.name}
                                secondary={
                                    <React.Fragment>
                                        <Typography component="span" variant="body2">
                                            {tool.image}
                                        </Typography>
                                    </React.Fragment>
                                }
                            />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" onClick={() => onDeleteToolRequest(index)}>
                                    <DeleteIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    )
                })}
            </List>

            <Fab
                variant="extended"
                size="small"
                color="primary"
                onClick={() => setState({ ...state, isToolDialogOpen: true })}
            >
                <AddIcon />
                Add new
            </Fab>

            <AddOrEditToolDialog
                isOpen={state.isToolDialogOpen}
                isBusy={false}
                onCloseRequest={() =>
                    setState(prevState => ({ ...prevState, isToolDialogOpen: false }))
                }
                onConfirm={onNewToolAddRequest}
            />
        </>
    )
}

export default ToolsSelector
