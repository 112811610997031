import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import ForwardIcon from '@material-ui/icons/Forward'
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard'
import Workspace from '../../data/entities/Workspace'
import { blue, red } from '@material-ui/core/colors'
import { CircularProgress } from '@material-ui/core'
import { formatDateTime } from '../../../common/presentation/formatDateTime'

interface Props {
    workspace: Workspace
    onSelect: (workspace: Workspace) => void
    onEditRequest: (workspace: Workspace) => void
    onDeleteRequest: (workspace: Workspace) => void
    isSelected: boolean
    isBeingDeleted: boolean
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        errorAvatar: {
            margin: 10,
            backgroundColor: red[500],
        },
        activeAvatar: {
            margin: 10,
        },
        pendingAvatar: {
            margin: 10,
            backgroundColor: blue[300],
        },
    })
)

const STATUS_TEXTS_BY_ENUM = {
    PENDING_WAITING_FOR_BUILD: 'Waiting for workspace initialization to finish...',
    PENDING_WAITING_FOR_STARTUP: 'Workspace initialized. Waiting for startup to finish...',
    ACTIVE: 'Workspace is fully available',
    ERROR: 'An error has occurred while initializing the workspace',
}

const getClassNameForStatus = (workspace: Workspace, classes: any) => {
    switch (workspace.status) {
        case 'ACTIVE':
            return classes.activeAvatar
        case 'PENDING_WAITING_FOR_BUILD':
        case 'PENDING_WAITING_FOR_STARTUP':
            return classes.pendingAvatar
        case 'ERROR':
            return classes.errorAvatar
    }
}

export default function WorkspaceCard({
    workspace,
    onSelect,
    onEditRequest,
    onDeleteRequest,
    isBeingDeleted,
}: Props) {
    const classes = useStyles()
    return (
        <Card>
            <CardHeader
                avatar={
                    <Avatar className={getClassNameForStatus(workspace, classes)}>
                        <DeveloperBoardIcon />
                    </Avatar>
                }
                title={workspace.name}
                subheader={formatDateTime(workspace.created)}
            />
            <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                    {STATUS_TEXTS_BY_ENUM[workspace.status]}
                </Typography>
            </CardContent>
            <CardActions disableSpacing>
                {workspace.status === 'ACTIVE' && (
                    <IconButton onClick={() => onEditRequest(workspace)}>
                        <EditIcon />
                    </IconButton>
                )}
                {['ACTIVE', 'ERROR'].includes(workspace.status) && (
                    <IconButton onClick={() => onDeleteRequest(workspace)}>
                        {isBeingDeleted ? <CircularProgress size={24} /> : <DeleteIcon />}
                    </IconButton>
                )}
                {workspace.status === 'ACTIVE' && (
                    <IconButton onClick={() => onSelect(workspace)}>
                        <ForwardIcon />
                    </IconButton>
                )}
                {(workspace.status === 'PENDING_WAITING_FOR_BUILD' ||
                    workspace.status === 'PENDING_WAITING_FOR_STARTUP') && (
                    <CircularProgress size={24} />
                )}
            </CardActions>
        </Card>
    )
}
