import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAppState } from '../AppStateContext'

function ProtectedRoute(props: any) {
    const [appState] = useAppState()
    const { component: Component, ...rest } = props

    const isSignedIn = appState.jwtToken !== null
    if (isSignedIn) {
        return <Route {...rest} render={props => <Component {...props} />} />
    }

    return (
        <Route
            {...rest}
            render={props => (
                <Redirect
                    to={{
                        pathname: '/',
                        state: { from: props.location },
                    }}
                />
            )}
        />
    )
}

export default ProtectedRoute
