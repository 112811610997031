import * as React from 'react'
import {useState} from 'react'
import Pipeline from '../../data/entities/Pipeline'
import List from '@material-ui/core/List/List'
import {deepOrange, green, grey, red} from '@material-ui/core/colors'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Execution, {ExecutionStatus} from '../../data/entities/Execution'
import {ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Typography,} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PipelineDetails from './PipelineDetails'

interface Props {
    pipelines: Array<Pipeline>
    onBuildTrigger: (pipeline: Pipeline) => void
}

interface State {
    expandedPipelineName: string | null
}

const MARKER_BASE_STYLE = {
    display: 'inline-block',
    width: 7,
    height: 7,
    borderRadius: '100%',
    marginLeft: 10,
    marginBottom: 1,
}

const useStyles = makeStyles(theme => ({
    list: {
        paddingTop: 0,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    secondaryHeadingLink: {
        fontSize: theme.typography.pxToRem(15),
    },
    failureMarker: {
        ...MARKER_BASE_STYLE,
        background: red[500],
    },
    successMarker: {
        ...MARKER_BASE_STYLE,
        background: green[500],
    },
    warningMarker: {
        ...MARKER_BASE_STYLE,
        backgroundColor: deepOrange[500],
    },
    abortedMarker: {
        ...MARKER_BASE_STYLE,
        backgroundColor: grey[500],
    },
    noBuildsYetMarker: {
        ...MARKER_BASE_STYLE,
        background: grey[500],
    },
}))

interface BuildStateMarkerProps {
    executions: Array<Execution>
    classes: Record<any, any>
}

const BuildStateMarker = ({ executions, classes }: BuildStateMarkerProps) => {
    const className = findAvatarClassName(executions, classes)
    return <div className={className}></div>
}

const findAvatarClassName = (executions: Array<Execution>, classes: any) => {
    if (!executions.length) {
        return classes.noBuildsYetMarker
    }

    const latestExecution = executions[0]
    if (latestExecution.status === ExecutionStatus.SUCCESS) {
        return classes.successMarker
    }

    return classes.failureMarker
}

const findAvatarText = (executions: Array<Execution>) => {
    if (!executions.length) {
        return 'No executions yet.'
    }

    const latestExecution = executions[0]

    switch (latestExecution.status) {
        case ExecutionStatus.SUCCESS: return 'Latest build was successful';
        case ExecutionStatus.UNSTABLE: return 'Latest build was unstable';
        case ExecutionStatus.ABORTED: return 'Latest build was aborted';
        default: return 'Latest build failed';
    }
}

function Pipelines({ pipelines, onBuildTrigger }: Props) {
    const [state, setState] = useState<State>({ expandedPipelineName: null })
    const classes = useStyles()

    const isExpanded = (pipeline: Pipeline) => state.expandedPipelineName === pipeline.name

    const toggleExpansion = (pipeline: Pipeline) => {
        let { expandedPipelineName } = state
        if (isExpanded(pipeline)) {
            expandedPipelineName = null
        } else {
            expandedPipelineName = pipeline.name
        }
        setState({
            expandedPipelineName,
        })
    }

    return (
        <>
            <List className={classes.list}>
                {pipelines.map(pipeline => {
                    const executions = pipeline.executions
                    const lastBuild = findAvatarText(executions)
                    const executionsSummary = executions.length
                        ? `, ${executions.length} executions`
                        : ''
                    const secondaryHeading = `${lastBuild}${executionsSummary}`
                    return (
                        <ExpansionPanel
                            key={pipeline.name}
                            expanded={isExpanded(pipeline)}
                            onChange={() => toggleExpansion(pipeline)}
                        >
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.heading}>
                                    <b>Pipeline</b>&nbsp;{pipeline.name}
                                    <BuildStateMarker executions={executions} classes={classes} />
                                </Typography>
                                <Typography className={classes.secondaryHeading}>
                                    {secondaryHeading}
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <PipelineDetails
                                    pipeline={pipeline}
                                    onBuildTrigger={() => onBuildTrigger(pipeline)}
                                />
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    )
                })}
            </List>
        </>
    )
}

export default Pipelines
