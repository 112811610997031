import * as React from 'react'
import { ReactNode } from 'react'
import { Button, makeStyles, Paper, Typography } from '@material-ui/core'
import EntitySelectorTabs, { Option } from './EntitySelectorTabs'
import Grid from '@material-ui/core/Grid/Grid'

interface Props<T> {
    title: string
    tabsColor: string
    toOption: (item: T) => Option
    values: Array<T>
    currentValue: T | null
    onSelect: (item: T) => void
    createButtonLabel: string
    onCreateRequest: () => void
    children: ReactNode
}

const useStyles = makeStyles({
    root: {
        background: '#f8f8f8',
        marginBottom: 48,
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    headline: {
        width: '100%',
        color: '#555',
        marginBottom: 16,
        flex: 1,
    },
    createNewButton: {
        marginRight: 16,
    },
})

function CurrentWorkspaceSectionPaper<T>(props: Props<T>) {
    const classes = useStyles()

    return (
        <Grid item xs={10}>
            <Paper className={classes.root}>
                <div className={classes.header}>
                    <Typography
                        className={classes.headline}
                        variant="h4"
                        style={{ padding: 32, paddingBottom: 8 }}
                    >
                        {props.title}
                    </Typography>

                    <Button
                        onClick={props.onCreateRequest}
                        color="primary"
                        className={classes.createNewButton}
                    >
                        {props.createButtonLabel}
                    </Button>
                </div>

                <EntitySelectorTabs
                    tabsColor={props.tabsColor}
                    values={props.values}
                    currentValue={props.currentValue}
                    toOption={props.toOption}
                    onSelect={props.onSelect}
                />

                <div style={{ padding: 32 }}>{props.children}</div>
            </Paper>
        </Grid>
    )
}

export default CurrentWorkspaceSectionPaper
