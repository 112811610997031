import React from 'react'
import Grid from '@material-ui/core/Grid/Grid'
import Pipelines from './Pipelines'
import Pipeline from '../../data/entities/Pipeline'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { CircularProgress, Paper, Typography } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import Facts from '../../../common/presentation/components/Facts'
import Tool, { ToolStatus } from '../../../workspaces/data/entities/Tool'

interface Props {
    tool: Tool
    onBuildTrigger: (pipeline: Pipeline) => void
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: 16,
        minHeight: 214,
    },
    subHeadline: {
        color: grey[600],
        marginBottom: 16,
    },
}))

interface ToolFactsProps {
    classes: Record<any, any>
    tool: Tool
}

const ToolFacts = ({ classes, tool }: ToolFactsProps) => {
    switch (tool.status) {
        case ToolStatus.READY:
            const facts = [
                {
                    label: 'Site',
                    node: tool.siteUrl ? (
                        <a href={tool.siteUrl} target="_blank">
                            {tool.siteUrl}
                        </a>
                    ) : (
                        'Building ...'
                    ),
                },
                {
                    label: 'Build job',
                    node: tool.jenkinsUrl ? (
                        <a href={tool.jenkinsUrl!} target="_blank">
                            {tool.jenkinsUrl}
                        </a>
                    ) : (
                        'Building ...'
                    ),
                },
            ]

            return <Facts facts={facts} />

        case ToolStatus.NOT_STARTED:
        case ToolStatus.IN_PROGRESS:
            return (
                <CircularProgress
                    size={48}
                    style={{
                        display: 'block',
                        marginTop: 32,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    }}
                />
            )

        case ToolStatus.ERROR:
            return (
                <Typography variant="body2">
                    An error occurred while installing this tool. See{' '}
                    <a href={tool.jenkinsUrl!} target="_blank">
                        the Jenkins build job
                    </a>{' '}
                    for further information.
                </Typography>
            )
    }
}

function ToolDetails(props: Props) {
    const classes = useStyles()

    return (
        <Grid container spacing={4}>
            <Grid item xs={3}>
                <Typography variant="h5">{props.tool.name}</Typography>
                <Typography variant="subtitle1" className={classes.subHeadline}>
                    <b>{props.tool.image}</b>
                </Typography>

                <ToolFacts classes={classes} tool={props.tool} />
            </Grid>

            <Grid item xs={9}>
                <Pipelines pipelines={props.tool.pipelines} onBuildTrigger={props.onBuildTrigger} />
            </Grid>
        </Grid>
    )
}

export default ToolDetails
