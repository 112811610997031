import Toolbar from '@material-ui/core/Toolbar/Toolbar'
import Typography from '@material-ui/core/Typography/Typography'
import AppBar from '@material-ui/core/AppBar/AppBar'
import React, { useContext, useEffect, useState } from 'react'
import { __RouterContext } from 'react-router'
import NavigationElements from './NavigationElements'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useAppState } from '../../AppStateContext'

const NAVIGATION_OPTIONS = [
    {
        label: 'All workspaces',
        pathName: '/workspaces',
    },
    {
        label: '',
        pathName: '/current-workspace', // TODO deeplinking with workspace ID
    },
]

const findSelectedTabValue = (currentPathName: string) => {
    const currentTab = NAVIGATION_OPTIONS.find(tab => currentPathName.includes(tab.pathName))
    return currentTab ? currentTab.pathName : null
}

function Title() {
    const useStyles = makeStyles({
        root: {
            marginRight: 48,
        },
        logo: {
            height: 50,
            marginTop: 10,
            marginBottom: 0
        }
    })

    const classes = useStyles()

    return (
        <div className={classes.root}>
            <img src='./logo.png' alt='DevOps@Scale' className={classes.logo}/>
            {/* <Typography variant="h6">Dev Ops Tier 1</Typography> */}
        </div>
    )
}

function NavBar() {
    const routerContext = useContext(__RouterContext)
    const [currentTabValue, setCurrentTabValue] = useState(NAVIGATION_OPTIONS[0].pathName)

    const [{ jwtToken, currentWorkspace }] = useAppState()
    const isUserLoggedIn = jwtToken !== null

    const updateTabByCurrentLocation = (location: any) => {
        const { pathname: currentPathname } = location
        const tabValue = findSelectedTabValue(currentPathname)
        setCurrentTabValue(tabValue!)
    }

    useEffect(() => {
        updateTabByCurrentLocation(routerContext.location)
        routerContext.history.listen(updateTabByCurrentLocation)
    })

    if (!isUserLoggedIn) {
        return <React.Fragment />
    }

    return (
        <AppBar position="fixed">
            <Toolbar>
                <Title />
                <NavigationElements
                    currentTabValue={currentTabValue}
                    tabs={NAVIGATION_OPTIONS}
                    onItemSelect={value => setCurrentTabValue(value)}
                />
            </Toolbar>
        </AppBar>
    )
}

export default NavBar
