import Tool from '../../workspaces/data/entities/Tool'

const restoreTool = (previouslySelectedTool: Tool | null, newlyLoadedTools: Array<Tool>) => {
    if (previouslySelectedTool === null) {
        if (newlyLoadedTools.length) {
            return newlyLoadedTools[0]
        }

        return null
    }

    const tool = newlyLoadedTools.find(newTool => newTool.name === previouslySelectedTool!.name)
    return tool || null
}

export default restoreTool
