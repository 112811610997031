import * as React from 'react'
import Workspace from '../../data/entities/Workspace'
import { Grid } from '@material-ui/core'
import WorkspaceCard from './WorkspaceCard'

interface Props {
    workspaces: Array<Workspace>
    workspacesBeingDeleted: Array<Workspace>
    onSelect: (workspace: Workspace) => void
    onEditRequest: (workspace: Workspace) => void
    onDeleteRequest: (workspace: Workspace) => void
    currentWorkspace: Workspace | null
}

function Workspaces({
    workspaces,
    workspacesBeingDeleted,
    onSelect,
    onEditRequest,
    onDeleteRequest,
    currentWorkspace,
}: Props) {
    return (
        <Grid container spacing={4}>
            {workspaces.map(workspace => (
                <Grid item xs={4}>
                    <WorkspaceCard
                        workspace={workspace}
                        onSelect={onSelect}
                        onEditRequest={onEditRequest}
                        onDeleteRequest={onDeleteRequest}
                        isBeingDeleted={workspacesBeingDeleted.some(ws => ws.id === workspace.id)}
                        isSelected={
                            currentWorkspace !== null && currentWorkspace.id === workspace.id
                        }
                    />
                </Grid>
            ))}
        </Grid>
    )
}

export default Workspaces
