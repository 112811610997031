import Artifact, { ArtifactStatus } from '../data/entities/Artifact'
import { Option, OptionStatus } from '../../common/presentation/components/EntitySelectorTabs'

const convertArtifactToOption = (artifact: Artifact): Option => {
    const name = artifact.name
    let status
    switch (artifact.status) {
        case ArtifactStatus.ACTIVE:
            status = OptionStatus.SUCCESS
            break
        case ArtifactStatus.PENDING:
            status = OptionStatus.PENDING
            break
        case ArtifactStatus.ERROR:
            status = OptionStatus.ERROR
            break
        default:
            throw new Error('Unknown status: ' + status)
    }

    return {
        name,
        status,
    }
}

export default convertArtifactToOption
