import * as React from 'react'
import { ReactNode } from 'react'
import {
    CircularProgress,
    createStyles,
    makeStyles,
    Tab,
    Tabs,
    withStyles,
} from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'
import { grey, red } from '@material-ui/core/colors'

export enum OptionStatus {
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
    PENDING = 'PENDING',
}

export interface Option {
    name: string
    status: OptionStatus
}

interface Props<T> {
    tabsColor: string
    toOption: (item: T) => Option
    values: Array<T>
    currentValue: T | null
    onSelect: (item: T) => void
}

const useStyles = makeStyles({
    root: {
        padding: '0 32px',
    },
    pendingProgress: {
        marginLeft: 8,
        marginTop: -10,
    },
    warning: {
        transform: 'translateY(6px)',
        marginLeft: 7,
        width: 20,
        fill: red[800],
    },
    tabLabel: {
        flexDirection: 'row',
    },
})

interface LabelProps {
    classes: Record<any, any>
    option: Option
}

interface StyledTabProps {
    label: string | ReactNode
}

const StyledTab = withStyles(() =>
    createStyles({
        root: {
            color: grey[700],
            '&$selected': {
                color: grey[900],
            },
        },
        selected: {},
    })
)((props: StyledTabProps) => <Tab {...props} />)

const isSame = (value: any, expectedValue: any) => {
    if (!expectedValue) {
        return false
    }

    const keys = Object.keys(value)
    if (keys.includes('id')) {
        return value.id === expectedValue.id
    }

    if (keys.includes('name')) {
        return value.name === expectedValue.name
    }
    return JSON.stringify(value) === JSON.stringify(expectedValue)
} // TODO user more sophisticated solution

const Label = ({ classes, option }: LabelProps) => {
    switch (option.status) {
        case OptionStatus.SUCCESS:
            return <span>{option.name}</span>

        case OptionStatus.PENDING:
            return (
                <div className={classes.tabLabel}>
                    <span>{option.name}</span>
                    <CircularProgress className={classes.pendingProgress} size={20} />
                </div>
            )

        case OptionStatus.ERROR:
            return (
                <div className={classes.tabLabel}>
                    <span>{option.name}</span>
                    <WarningIcon className={classes.warning} />
                </div>
            )
    }

    throw new Error('Unknown option status: ' + option.status)
}

function EntitySelectorTabs<T>(props: Props<T>) {
    const classes = useStyles()

    const onChange = (event: React.ChangeEvent<{}>, selectedIndex: number) => {
        props.onSelect(props.values[selectedIndex])
    }

    let noValuesPlaceholder = null
    if (!props.values.length) {
        noValuesPlaceholder = <i>No entries yet.</i>
    }

    let currentItemIndex = props.values.findIndex(value => isSame(value, props.currentValue))
    if (props.currentValue && props.values.length && currentItemIndex < 0) {
        throw Error('Provided value cannot be found in list of all value')
    }

    if (props.values.length == 0 && currentItemIndex < 0) {
        return (
            <div className={classes.root} style={{ background: props.tabsColor }}>
                <i>No entries yet.</i>
            </div>
        )
    }

    return (
        <div className={classes.root} style={{ background: props.tabsColor }}>
            <Tabs
                value={currentItemIndex}
                onChange={onChange}
                indicatorColor="primary"
                textColor="secondary"
                variant="scrollable"
            >
                {props.values
                    .map(v => props.toOption(v))
                    .map(option => (
                        <StyledTab label={<Label classes={classes} option={option} />} />
                    ))}

                {!!noValuesPlaceholder && noValuesPlaceholder}
            </Tabs>
        </div>
    )
}

export default EntitySelectorTabs
