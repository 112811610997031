import React from 'react'
import Artifact, { ArtifactStatus } from '../../data/entities/Artifact'
import Grid from '@material-ui/core/Grid/Grid'
import Pipelines from './Pipelines'
import Pipeline from '../../data/entities/Pipeline'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Box, CircularProgress, Paper, Typography } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { formatDateTime } from '../../../common/presentation/formatDateTime'
import Facts from '../../../common/presentation/components/Facts'
import Button from '@material-ui/core/Button';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser'
import { TokenStorage } from '../../../signin/data/TokenStorage'
import { decodeToken } from "react-jwt"

interface Props {
    artifact: Artifact
    workspaceName: String
    onBuildTrigger: (pipeline: Pipeline) => void
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: 16,
        minHeight: 214,
    },
    actions: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'flex-end',
    },
    subHeadline: {
        color: grey[600],
        marginBottom: 16,
    },
}))

interface ArtifactFactsProps {
    classes: Record<any, any>
    artifact: Artifact
    workspaceName: String
}

const ArtifactFacts = ({ classes, artifact, workspaceName }: ArtifactFactsProps) => {

    const tokenStorage = TokenStorage.getInstance()

    const openInIDE = () => {
        const decodedToken = decodeToken(tokenStorage.getToken() as string) as any
        const email = decodedToken['email'] as string
        const openChe = () => {
            const devfileUrl = "https://middleware.platform.devops-at-scale.com/artifacts/devfile"
            window.open("https://ide.platform.devops-at-scale.com/#" + devfileUrl + "/" + workspaceName + "/" + artifact.name , '_blank')
        }
        // todo: obtain api token for che, check if devspace already exists and only create if not
        openChe()
    }

    const openInGitLab = () => {
         window.open(artifact.gitLabUrl === null ? "" : artifact.gitLabUrl, '_blank')
    }    

    switch (artifact.status) {
        case ArtifactStatus.ACTIVE:
            const facts = [
                { label: 'Created', node: formatDateTime(artifact.created) },
                { label: 'Last updated', node: formatDateTime(artifact.lastUpdated) },
                {
                    label: 'Build Job',
                    node: (
                        <a href={artifact.jenkinsUrl} target="_blank">
                            {artifact.jenkinsUrl}
                        </a>
                    ),
                },
                {
                    label: 'SonarQube',
                    node: (
                        <a href={'https://baseops.platform.devops-at-scale.com/sonarqube/dashboard?id=' + artifact.name} target="_blank">
                            {'https://baseops.platform.devops-at-scale.com/sonarqube/dashboard?id=' + artifact.name}
                        </a>
                    ),
                },                
                {
                    label: 'Test Environment',
                    node: artifact.siteUrl ? (
                        <a href={artifact.siteUrl + "/"} target="_blank">
                            {artifact.siteUrl + "/"}
                        </a>
                    ) : (
                        <span>-</span>
                    ),
                },
                {
                    label: 'Git Clone URl',
                    node: (
                        <div>
                            <a href={artifact.sourceCodeRepositoryUrl} target="_blank">
                            {artifact.sourceCodeRepositoryUrl}
                            </a>
                            <Box m={2} />
                            <div className={classes.actions}>
                			    <Button variant="outlined"	color="primary"className={classes.buildButton}
                    			    	onClick={openInGitLab} >
                    			    <OpenInBrowserIcon className={classes.buildButtonIcon} />
                    				Open Wiki/Docs
                			    </Button>
						    </div>                            
                            <Box m={2} />
                            <div className={classes.actions}>
                			    <Button variant="outlined"	color="primary"className={classes.buildButton}
                    			    	onClick={openInIDE} >
                    			    <OpenInBrowserIcon className={classes.buildButtonIcon} />
                    				Open in new IDE
                			    </Button>
						    </div>
                        </div>
                    ),
                },

            ]

            return <Facts facts={facts} />

        case ArtifactStatus.PENDING:
            return (
                <CircularProgress
                    size={48}
                    style={{
                        display: 'block',
                        marginTop: 32,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    }}
                />
            )

        case ArtifactStatus.ERROR:
            return (
                <Typography variant="body2">
                    An error occurred while creating this artifact. See{' '}
                    <a href={artifact.jenkinsUrl} target="_blank">
                        the Jenkins build job
                    </a>{' '}
                    for further information.
                </Typography>
            )
    }
}

function ArtifactDetails(props: Props) {
    const classes = useStyles()

    return (
        <Grid container spacing={4}>
            <Grid item xs={3}>
                <Typography variant="h5">{props.artifact.name}</Typography>
                <Typography variant="subtitle1" className={classes.subHeadline}>
                    <b>Flask 0.6,</b>&nbsp;Python 3.7
                </Typography>

                <ArtifactFacts classes={classes} artifact={props.artifact} workspaceName={props.workspaceName} />
            </Grid>

            <Grid item xs={9}>
                <Pipelines
                    pipelines={props.artifact.pipelines}
                    onBuildTrigger={props.onBuildTrigger}
                />
            </Grid>
        </Grid>
    )
}

export default ArtifactDetails
