const STORAGE_KEY = 'jwtToken'

export class TokenStorage {
    private static instance: TokenStorage | null = null
    private tokenCache: string | null = null

    private constructor() {
        this.tokenCache = window.localStorage.getItem(STORAGE_KEY)
    }

    static getInstance() {
        if (!this.instance) {
            this.instance = new TokenStorage()
        }
        return this.instance
    }

    storeToken(token: string) {
        this.tokenCache = token
        window.localStorage.setItem(STORAGE_KEY, token)
    }

    getToken() {
        return this.tokenCache
    }

    deleteToken() {
        this.tokenCache = null
        window.localStorage.removeItem(STORAGE_KEY)
    }
}
