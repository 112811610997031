import React from 'react'
import Grid from '@material-ui/core/Grid/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Button, Chip, Paper, Typography } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import Environment from '../../../workspaces/data/entities/Environment'
import EditIcon from '@material-ui/icons/Edit'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import Artifact from '../../data/entities/Artifact'
import Pipeline from '../../data/entities/Pipeline'
import DeploymentResult from './DeploymentResult'

interface Props {
    environment: Environment
    pipelines: Array<{ pipeline: Pipeline; artifact: Artifact }>
    onEditRequest: (environment: Environment) => void
    onDeployRequest: (environment: Environment) => void
}

const useStyles = makeStyles({
    subHeadline: {
        color: grey[600],
        marginBottom: 16,
    },
    actionButton: {
        marginRight: 8,
        marginTop: 16,
    },
    artifactPaper: {
        padding: 16,
    },
})

function EnvironmentDetails(props: Props) {
    const classes = useStyles()

    const addThousandGroupSeparators = (x: number) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }

    const MOCK_DATASETS: Array<{ name: string; itemCount: number; description: string }> = [
        {
            name: 'Q4/2018 user transactions',
            itemCount: 1821600,
            description:
                'Anonymized user transaction data from Sep-Dec 2018, incl. SKUs and prices',
        },
    ]

    return (
        <Grid container spacing={4}>
            <Grid item xs={3}>
                <Typography variant="h5">{props.environment.name}</Typography>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => props.onEditRequest(props.environment)}
                    className={classes.actionButton}
                >
                    <EditIcon style={{ marginRight: 8 }} />
                    Edit
                </Button>

                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => props.onDeployRequest(props.environment)}
                    className={classes.actionButton}
                >
                    <LocalShippingIcon style={{ marginRight: 8 }} />
                    Deploy
                </Button>
            </Grid>

            <Grid item container xs={9} spacing={2}>
                {props.pipelines.map(item => {
                    const { artifact, pipeline } = item
                    const version = props.environment.artifactReleasesByArtifactIds[artifact.id]

                    const latestExecution =
                        pipeline && pipeline.executions.length ? pipeline.executions[0] : null

                    return (
                        <Grid item xs={4}>
                            <Paper className={classes.artifactPaper}>
                                <Typography variant="subtitle1">{artifact.name}</Typography>
                                <Chip
                                    label="Artifact"
                                    style={{ marginBottom: 36, marginRight: 4 }}
                                    size="small"
                                    variant="outlined"
                                />
                                <Chip
                                    label={version || 'None'}
                                    style={{ marginBottom: 36 }}
                                    color="primary"
                                    size="small"
                                />

                                {latestExecution ? (
                                    <DeploymentResult execution={latestExecution} />
                                ) : (
                                    <Typography variant="body2">No deployment yet.</Typography>
                                )}
                            </Paper>
                        </Grid>
                    )
                })}
                {MOCK_DATASETS.map(dataset => {
                    return (
                        <Grid item xs={3}>
                            <Paper className={classes.artifactPaper}>
                                <Typography variant="subtitle1">{dataset.name}</Typography>
                                <Chip
                                    label="Dataset"
                                    style={{ marginBottom: 11 }}
                                    size="small"
                                    variant="outlined"
                                />
                                <Typography variant="subtitle2" style={{ marginBottom: 4 }}>
                                    {addThousandGroupSeparators(dataset.itemCount)} items
                                </Typography>

                                <Typography variant="body2">{dataset.description}</Typography>
                            </Paper>
                        </Grid>
                    )
                })}
            </Grid>
        </Grid>
    )
}

export default EnvironmentDetails
