import * as React from 'react'
import { useState } from 'react'
import {
    Avatar,
    Fab,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    makeStyles,
    Typography,
} from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import AddIcon from '@material-ui/icons/Add'
import Environment from '../../../data/entities/Environment'
import AddOrEditEnvironmentDialog from './AddOrEditEnvironmentDialog'
import hintStyle from './hintStyle'
import Artifact from '../../../../currentworkspace/data/entities/Artifact'

interface Props {
    artifacts: Array<Artifact>
    environments: Array<Environment>
    onChange: (environments: Array<Environment>) => void
}
interface State {
    isEnvironmentDialogOpen: boolean
    selectedEnvironment: Environment | null
}

const DEFAULT_STATE: State = {
    isEnvironmentDialogOpen: false,
    selectedEnvironment: null,
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    avatar: {
        backgroundColor: green[500],
    },
    hint: hintStyle,
}))

function EnvironmentsSelector({ artifacts, environments, onChange }: Props) {
    const classes = useStyles()
    const [state, setState] = useState({ ...DEFAULT_STATE })

    const onEnvironmentUpdateRequest = (environment: Environment, previousName: string) => {
        const existingEnvIndex = environments.findIndex(
            existingEnv => existingEnv.name === previousName
        )

        const changedEnvironments = [...environments]

        if (existingEnvIndex > -1) {
            changedEnvironments[existingEnvIndex] = environment
        } else {
            changedEnvironments.push(environment)
        }

        onChange(changedEnvironments)

        setState({ ...state, isEnvironmentDialogOpen: false, selectedEnvironment: null })
    }

    return (
        <>
            <Typography variant="body2" className={classes.hint}>
                Manage combinations of released versions per artifact as environments.
            </Typography>

            <List dense className={classes.root}>
                {environments.map(environment => {
                    return (
                        <ListItem
                            key={environment.name}
                            button
                            onClick={() =>
                                setState(prevState => ({
                                    ...prevState,
                                    isEnvironmentDialogOpen: true,
                                    selectedEnvironment: environment,
                                }))
                            }
                        >
                            <ListItemAvatar>
                                <Avatar className={classes.avatar}>
                                    {environment.name[0].toUpperCase()}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={environment.name} />
                        </ListItem>
                    )
                })}
            </List>

            <Fab
                variant="extended"
                size="small"
                color="primary"
                onClick={() => setState({ ...state, isEnvironmentDialogOpen: true })}
            >
                <AddIcon />
                Add new
            </Fab>

            <AddOrEditEnvironmentDialog
                artifacts={artifacts}
                environment={state.selectedEnvironment}
                isOpen={state.isEnvironmentDialogOpen}
                isBusy={false}
                onCloseRequest={() =>
                    setState(prevState => ({
                        ...prevState,
                        isEnvironmentDialogOpen: false,
                        selectedEnvironment: null,
                    }))
                }
                onConfirm={onEnvironmentUpdateRequest}
            />
        </>
    )
}

export default EnvironmentsSelector
