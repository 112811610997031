import * as React from 'react'
import { ChangeEvent, useState } from 'react'
import Grid from '@material-ui/core/Grid/Grid'
import SignInApi from '../data/SignInApi'
import { useAppState } from '../../common/presentation/AppStateContext'
import { Redirect } from 'react-router'
import AppActionType from '../../AppActionType'
import SignInError, { SignInErrorType } from '../data/exceptions/SignInError'
import Typography from '@material-ui/core/Typography/Typography'
import { makeStyles, Paper } from '@material-ui/core'
import logo from '../../img/logo-tmp.png'

interface State {
    currentUserName: string
    currentPassword: string
    isLoading: boolean
    error: SignInErrorType | null
}

const DEFAULT_STATE = {
    currentUserName: '',
    currentPassword: '',
    isLoading: false,
    error: null,
}

const useStyles = makeStyles({
    paper: {
        boxSizing: 'border-box',
        padding: 32,
        width: 330,
        margin: '0 auto',
    },
    logoContainer: {
        width: 330,
        textAlign: 'center',
    },
    logo: {
        width: 252,
        marginBottom: 12,
    },
    platinionLogo: {
        width: 196,
        marginTop: 24,
    },
    formElement: {
        marginTop: 8,
    },
    button: {
        width: 130,
        marginTop: 24,
    },
    error: {
        marginTop: 18,
        lineHeight: '12px',
        fontSize: 14,
    },
})

const api = new SignInApi(process.env.REACT_APP_MIDDLEWARE_BASE_URL!)

function SignInScreen() {
    const [state, setState] = useState<State>({ ...DEFAULT_STATE })
    const [appState, dispatch] = useAppState()
    const classes = useStyles()

    const isUserSignedIn = appState.jwtToken !== null
    if (isUserSignedIn) {
        return <Redirect to="/workspaces" />
    }
    
    // The goal of the next few lines of code is to do the next operations:
    // 1. The frontend will call the backend /account/login route in order
    // to trigger the WS2OIS secure login.
    // 2. When the process is successful it will then generate a JWT token
    // and redirects back to the frontend app with the token on the querystring.
    // 3. When the token is received on the querystring we change the state
    // and store the token within this change
    // 4. After the appState changes it goes to workspaces in order to
    // continue
    const {location} = window
    const queryParams = new URLSearchParams(location.search)
    const jwtToken = queryParams.get("token")

    if (jwtToken != null) {
        queryParams.delete("token")
        // Removes the token from the querystring, so it wont be visible on the url
        window.history.replaceState({}, '', location.origin );
        dispatch({ type: AppActionType.STORE_SIGN_IN, token: jwtToken })
    } else {
        // Foces redirection to the WS2OIS backend call.
        window.location.href = process.env.REACT_APP_MIDDLEWARE_BASE_URL + "/account/login"
    }

    // Removed the button, the textBoxes, and related transformations
    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ minHeight: '100vh' }}
        >
            <Grid item container xs={6} justify="center">
                <Grid item xs={12}>
                    <Paper className={classes.paper} >
                        <Grid container justify="center" alignItems="center" direction="column">
                            <Grid item xs={12}>
                                <img src={logo} className={classes.logo} />
                            </Grid>

                            {state.error && (
                                <Typography variant="body1" color="error" className={classes.error}>
                                    {state.error === SignInErrorType.UNAUTHORIZED
                                        ? 'The credentials your provided are invalid.'
                                        : 'An error ocurred, please try again later.'}
                                </Typography>
                            )}
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={6} className={classes.logoContainer}>
                    <img
                        className={classes.platinionLogo}
                        src="https://karriere.bcgplatinion.de/wp-content/themes/bcgp-karriere/images/layout/Logo_platinion_test_dark.png"
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default SignInScreen
