import * as React from 'react'
import Pipeline from '../../data/entities/Pipeline'
import { Button, Grid, makeStyles, Typography } from '@material-ui/core'
import BuildIcon from '@material-ui/icons/Build'
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser'
import LatestExecution from './LatestExecution'

interface Props {
    pipeline: Pipeline
    onBuildTrigger: () => void
}

const useStyles = makeStyles({
    actions: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'flex-end',
    },
    buildButton: {
        margin: 8,
    },
    buildButtonIcon: {
        marginRight: 8,
    },
})

function PipelineDetails({ pipeline, onBuildTrigger }: Props) {
    const classes = useStyles()
    const executions = pipeline.executions
    const latestExecution = executions.length ? executions[0] : null
    const pastExecutions = executions.slice(1)

    // const isBuilding =
    //     latestExecution &&
    //     [ExecutionStatus.IN_PROGRESS, ExecutionStatus.NOT_EXECUTED].includes(latestExecution.status)

    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                {latestExecution ? (
                    <LatestExecution execution={latestExecution} pastExecutions={pastExecutions} />
                ) : (
                    <Typography variant="body2">
                        This pipeline has not been executed yet. Click the button below to trigger a
                        new build.
                    </Typography>
                )}
            </Grid>

            <div className={classes.actions}>
                <Button
                    variant="outlined"
                    color="primary"
                    className={classes.buildButton}
                    onClick={() => window.open(pipeline.url, '_blank')}
                >
                    <OpenInBrowserIcon className={classes.buildButtonIcon} />
                    Open in jenkins
                </Button>

                <Button
                    variant="contained"
                    color="primary"
                    className={classes.buildButton}
                    onClick={() => onBuildTrigger()}
                >
                    <BuildIcon className={classes.buildButtonIcon} />
                    Start build
                </Button>
            </div>
        </Grid>
    )
}

export default PipelineDetails
