import * as React from 'react'
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    TextField,
    Typography,
} from '@material-ui/core'
import { useState, useEffect } from 'react'
import { ChangeEvent } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Pipeline from '../../data/entities/Pipeline'
import BuildParameter from '../../data/entities/BuildParameter'
import splitVersioncode from '../../../common/data/splitVersioncode'

interface Props {
    isOpen: boolean
    isBusy: boolean
    pipeline?: Pipeline
    lastVersion: string
    onCloseRequest: () => void
    onConfirm: (parameters: BuildParameter) => void
}

interface State {
    versionMessage: string
    newVersion: string | null
    major: string
    minor: string
    fix: string
}

const DEFAULT_STATE = {
    versionMessage: '',
    newVersion: null,
    major: '0',
    minor: '0',
    fix: '1'
}

const useStyles = makeStyles({
    progress: {
        marginRight: 10,
    },
    formControl: {
        marginBottom: 16,
        marginRight: 16,
        maxWidth: 120,
    },
    formControlFull: {
        marginBottom: 16,
        width: '100%',
    },
})

function ReleaseParameterDialog({ isOpen, isBusy, lastVersion, onConfirm, onCloseRequest }: Props) {
    const [state, setState] = useState<State>({ ...DEFAULT_STATE })
    const classes = useStyles()

    useEffect(() => {
        const splitVersion = splitVersioncode(lastVersion);

        setState({
            ...state,
            major: splitVersion.MAJOR,
            minor: splitVersion.MINOR,
            fix: splitVersion.FIX
        });
    }, [lastVersion]);

    const onMessageChange = (event: ChangeEvent<HTMLInputElement>) =>
        setState({ ...state, versionMessage: event.currentTarget.value })

    const onMajorChange = (event: ChangeEvent<any>) => {
        setState({ ...state, major: event.currentTarget.value })
    }

    const onMinorChange = (event: ChangeEvent<any>) => {
        setState({ ...state, minor: event.currentTarget.value })
    }

    const onFixChange = (event: ChangeEvent<any>) => {
        setState({ ...state, fix: event.currentTarget.value })
    }

    const shouldDisableButton =
        state.versionMessage.length === 0 || !state.major || !state.minor || !state.fix || isBusy

    return (
        <Dialog open={isOpen} onClose={onCloseRequest} fullWidth={true}>
            <DialogTitle>Release artifact with new version</DialogTitle>
            <DialogContent>
                <Typography>Latest release: {lastVersion} </Typography>
                <FormControl fullWidth={true} className={classes.formControlFull}>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="message"
                        label="Version message"
                        type="text"
                        fullWidth
                        autoComplete="off"
                        onChange={onMessageChange}
                        disabled={isBusy}
                    />
                </FormControl>

                <FormControl fullWidth={false} className={classes.formControl}>
                    <TextField
                        value={state.major}
                        margin="dense"
                        id="major"
                        label="Major"
                        type="number"
                        autoComplete="off"
                        InputProps={{ inputProps: { min: 0, max: 99 } }}
                        onChange={onMajorChange}
                        disabled={isBusy}
                    />
                </FormControl>

                <FormControl fullWidth={false} className={classes.formControl}>
                    <TextField
                        value={state.minor}
                        margin="dense"
                        id="minor"
                        label="Minor"
                        type="number"
                        autoComplete="off"
                        InputProps={{ inputProps: { min: 0, max: 99 } }}
                        onChange={onMinorChange}
                        disabled={isBusy}
                    />
                </FormControl>


                <FormControl fullWidth={false} className={classes.formControl}>
                    <TextField
                        value={state.fix}
                        margin="dense"
                        id="fix"
                        label="Fix"
                        type="number"
                        autoComplete="off"
                        InputProps={{ inputProps: { min: 0, max: 99 } }}
                        onChange={onFixChange}
                        disabled={isBusy}
                    />
                </FormControl>

            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseRequest}>Cancel</Button>
                <Button
                    onClick={() => onConfirm({
                        MAJOR: state.major, 
                        MINOR: state.minor, 
                        FIX: state.fix, 
                        VERSIONMESSAGE: state.versionMessage
                    })}
                    color="primary"
                    disabled={shouldDisableButton}
                >
                    <CircularProgress
                        className={classes.progress}
                        style={{ opacity: isBusy ? 1 : 0 }}
                        size={16}
                    />
                    Release
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ReleaseParameterDialog
