import * as React from 'react'
import { useEffect, useState } from 'react'
import ArtifactsApi from '../data/ArtifactsApi'
import Artifact from '../data/entities/Artifact'
import Grid from '@material-ui/core/Grid'
import CircularProgressCentered from '../../common/presentation/components/CircularProgressCentered/CircularProgressCentered'
import { TokenStorage } from '../../signin/data/TokenStorage'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Pipeline from '../data/entities/Pipeline'
import { useAppState } from '../../common/presentation/AppStateContext'
import AddArtifactDialog from './components/AddArtifactDialog'
import restoreArtifact from './restoreArtifact'
import ArtifactDetails from './components/ArtifactDetails'
import { Redirect } from 'react-router'
import WorkspacesApi from '../../workspaces/data/WorkspacesApi'
import Tool, { ToolStatus } from '../../workspaces/data/entities/Tool'
import Environment from '../../workspaces/data/entities/Environment'
import ToolDetails from './components/ToolDetails'
import Workspace from '../../workspaces/data/entities/Workspace'
import EnvironmentDetails from './components/EnvironmentDetails'
import CurrentWorkspaceSectionPaper from '../../common/presentation/components/CurrentWorkspaceSectionPaper'
import convertArtifactToOption from './convertArtifactToOption'
import convertToolToOption from './convertToolToOption'
import AddOrEditToolDialog from '../../workspaces/presentation/components/AddWorkspacesDialog/AddOrEditToolDialog'
import convertEnvironmentToOption from './convertEnvironmentToOption'
import AddOrEditEnvironmentDialog from '../../workspaces/presentation/components/AddWorkspacesDialog/AddOrEditEnvironmentDialog'
import restoreTool from './restoreTool'
import restoreEnvironment from './restoreEnvironment'
import useInterval from '../../common/presentation/useInterval'
import BuildParameter from '../data/entities/BuildParameter'
import ReleaseParameterDialog from './components/ReleaseParameterDialog'
import BuildParameterDialog from './components/BuildParameterDialog'
import DeployParameterDialog from './components/DeployParameterDialog'

const useStyles = makeStyles({
    root: {
        padding: 32,
        width: '100%', // WORKAROUND for https://github.com/mui-org/material-ui/issues/7466
    },
    headline: {
        width: '100%',
        color: '#555',
        marginBottom: 16,
    },
    toolsPaper: {
        padding: 8,
    },
    sectionContainer: {
        marginBottom: 48,
    },
})

interface State {
    artifacts: Array<Artifact> | null
    workspace: Workspace | null
    selectedArtifact: Artifact | null
    selectedTool: Tool | null
    selectedEnvironment: Environment | null
    currentEnvironment: Environment | null
    activePipeline: Pipeline | null
    isAddArtifactDialogOpen: boolean
    isAddingArtifact: boolean
    isReleaseParameterDialogOpen: boolean
    isReleasingArtifact: boolean
    isBuildParameterDialogOpen: boolean
    isBuildingArtifact: boolean
    isDeployParameterDialogOpen: boolean
    isDeployingArtifact: boolean
    isAddToolDialogOpen: boolean
    isAddingTool: boolean
    isEnvironmentDialogOpen: boolean
    isProcessingEnvironment: boolean
}

const DEFAULT_STATE = {
    artifacts: null,
    workspace: null,
    selectedArtifact: null,
    selectedTool: null,
    selectedEnvironment: null,
    currentEnvironment: null,
    activePipeline: null,
    isAddArtifactDialogOpen: false,
    isAddingArtifact: false,
    isReleaseParameterDialogOpen: false,
    isReleasingArtifact: false,
    isDeployParameterDialogOpen: false,
    isDeployingArtifact: false,
    isBuildParameterDialogOpen: false,
    isBuildingArtifact: false,
    isAddToolDialogOpen: false,
    isAddingTool: false,
    isEnvironmentDialogOpen: false,
    isProcessingEnvironment: false,
}

const tokenStorage = TokenStorage.getInstance()
const workspacesApi = new WorkspacesApi(process.env.REACT_APP_MIDDLEWARE_BASE_URL!, tokenStorage)
const artifactsApi = new ArtifactsApi(process.env.REACT_APP_MIDDLEWARE_BASE_URL!, tokenStorage)

function CurrentWorkspaceScreen() {
    const [state, setState] = useState<State>({ ...DEFAULT_STATE })
    const [appState, dispatch] = useAppState()
    const classes = useStyles()

    useInterval(() => {
        workspacesApi.fetchAllEventsInWorkspace(appState.currentWorkspace).then(updatedWorkspaces => {
            const workspace = updatedWorkspaces.find(
                workspace => appState.currentWorkspace.id === workspace.id
            )
            if (!workspace) {
                throw new Error('The workspace you selected is not available anymore')
            }

            setState(prevState => ({
                ...prevState,
                workspace,
                selectedTool: restoreTool(prevState.selectedTool, workspace.tools),
                selectedEnvironment: restoreEnvironment(
                    prevState.selectedEnvironment,
                    workspace.environments
                ),
            }))
        })
    }, 10000)


    useEffect(() => {
        if (!appState.currentWorkspace) {
            return
        }

        artifactsApi
            .fetchAllInWorkspace(appState.currentWorkspace)
            .then(artifacts => {
                const selectedArtifact = artifacts.length ? artifacts[0] : null
                setState(prevState => ({
                    ...prevState,
                    artifacts,
                    selectedArtifact,
                }))
            })
            .catch(err => {
                alert('Error while fetching artifacts: ' + err.toString())
                throw err
            })
    }, [appState.currentWorkspace])

    useEffect(() => {
        if(!state.activePipeline) {
            return
        }
        switch (state.activePipeline.name) {
            case 'release':
                openReleaseDialog();
                break;
            case 'deploy':
                openDeployDialog();
                break;
            case 'build':
                openBuildDialog();
                break;
            default:
                break;
        }
    }, [state.activePipeline])

    useInterval(() => {
        artifactsApi.fetchAllEventsInArtifacts(appState.currentWorkspace).then(artifacts => {
            setState(prevState => {
                const selectedArtifact = restoreArtifact(artifacts, prevState.selectedArtifact)

                return { ...prevState, artifacts, selectedArtifact }
            })
        })
    }, 10000)

    if (!appState.currentWorkspace) {
        return <Redirect to="/workspaces" />
    }

    if (!state.artifacts || !state.workspace) {
        return <CircularProgressCentered />
    }

    const selectArtifact = (artifact: Artifact) => {
        return setState({
            ...state,
            selectedArtifact: artifact,
        })
    }

    const setActivePipeline = (pipeline: Pipeline) => {
        return setState({
            ...state,
            activePipeline: pipeline,
        })
    }

    const startBuildSelector = (pipeline: Pipeline) => {
        
        switch (pipeline.name) {
            case 'release':
                setActivePipeline(pipeline);
                break;
            case 'deploy':
                setActivePipeline(pipeline);
                break;
            case 'build': 
                setActivePipeline(pipeline);
                break;
            default:
                startBuild(pipeline);
                break;
        } 
    }

    const startParameterizedBuild = async (parameters: BuildParameter) => {
        setState({ ...state, isReleasingArtifact: true })
        console.log(state.activePipeline);
        
        if (state.activePipeline)
            startBuild(state.activePipeline, parameters)
        else
            alert('Something went wrong. No active pipeline selected for parameterized build')

        setState({
            ...state,
            isReleasingArtifact: true,
        });

        closeDialogs();
    }

    const startBuild = async (pipeline: Pipeline, parameters?: BuildParameter) => {
        try {
            await artifactsApi.triggerBuild(state.selectedArtifact!, pipeline, parameters)
            // TODO show progress
        } catch (error) {
            alert('there has been an error while triggering the build ' + error.toString())
        }
    }

    const startToolBuild = async (pipeline: Pipeline) => {
        try {
            await artifactsApi.triggerToolBuild(state.workspace!, state.selectedTool!, pipeline)
            // TODO show progress
        } catch (error) {
            alert('there has been an error while triggering the build ' + error.toString())
        }
    }

    const closeDialogs = () =>
        setState({
            ...state,
            isAddArtifactDialogOpen: false,
            isReleaseParameterDialogOpen: false,
            isBuildParameterDialogOpen: false,
            isDeployParameterDialogOpen: false,
            isAddToolDialogOpen: false,
            isEnvironmentDialogOpen: false
        })
    const openArtifactDialog = () => setState({ ...state, isAddArtifactDialogOpen: true })
    const openReleaseDialog = () => setState({ ...state, isReleaseParameterDialogOpen: true })
    const openBuildDialog = () => setState({ ...state, isBuildParameterDialogOpen: true })
    const openDeployDialog = () => setState({ ...state, isDeployParameterDialogOpen: true })
    const openToolDialog = () => setState({ ...state, isAddToolDialogOpen: true })
    const openEnvironmentDialog = (environment: Environment | null = null) =>
        setState({ ...state, isEnvironmentDialogOpen: true, currentEnvironment: environment })
    const createArtifact = async (name: string, artifactCodeRepo: string) => {
        setState({ ...state, isAddingArtifact: true })
        try {
            const newArtifact = await artifactsApi.createNew({
                name,
                workspaceId: state.workspace!.id,
                artifactCodeRepo: artifactCodeRepo
            })
            const fetchedArtifacts = state.artifacts || []
            fetchedArtifacts.push(newArtifact)

            setState({
                ...state,
                artifacts: fetchedArtifacts,
                isAddingArtifact: false,
                isAddArtifactDialogOpen: false,
            })
        } catch (error) {
            setState({
                ...state,
                isAddingArtifact: false,
            })
            alert('TODO handle error: ' + JSON.stringify(error))
        }
    }

    const createTool = async (tool: Tool) => {
        setState({ ...state, isAddingTool: true })

        const currentWorkspace = state.workspace!
        const tools = currentWorkspace.tools

        try {
            const workspace = { ...currentWorkspace, tools: [...tools, tool] }
            const updatedWorkspace = await workspacesApi.update(workspace)
            // dispatch({ type: AppActionType.USE_WORKSPACE, workspace: updatedWorkspace })

            setState({
                ...state,
                isAddingTool: false,
                isAddToolDialogOpen: false,
                isProcessingEnvironment: false,
                workspace: updatedWorkspace,
            })
        } catch (error) {
            alert('Error while updating workspace' + error.toString())
            setState({
                ...state,
                isAddingArtifact: false,
            })
        }
    }

    const createOrUpdateEnvironment = async (environment: Environment) => {
        setState({ ...state, isProcessingEnvironment: true })
        const { currentWorkspace } = appState

        const isNewEnvironment = !environment.id
        let updatedEnvironments

        if (isNewEnvironment) {
            updatedEnvironments = [...currentWorkspace.environments, environment]
        } else {
            updatedEnvironments = [...currentWorkspace.environments]
            const existingEnvIndex = updatedEnvironments.findIndex(
                existingEnv => existingEnv.id === environment.id
            )
            updatedEnvironments[existingEnvIndex] = environment
        }

        try {
            const workspace = {
                ...currentWorkspace,
                environments: updatedEnvironments,
            }

            const updatedWorkspace = await workspacesApi.update(workspace)
            // dispatch({ type: AppActionType.USE_WORKSPACE, workspace: updatedWorkspace })

            setState({
                ...state,
                isAddingTool: false,
                isAddToolDialogOpen: false,
                isProcessingEnvironment: false,
                workspace: updatedWorkspace,
            })
        } catch (error) {
            alert('Error while updating workspace' + error.toString())
            setState({
                ...state,
                isProcessingEnvironment: false,
            })
        }
    }

    const selectTool = (tool: Tool) => {
        setState({ ...state, selectedTool: tool })
    }

    const selectEnvironment = (environment: Environment) => {
        setState({ ...state, selectedEnvironment: environment })
    }

    const deployEnvironment = (environment: Environment) => {
        artifactsApi.triggerEnvironmentDeployment(state.workspace!, environment)
    }

    const deploymentPipelines = state.artifacts.map(artifact => {
        const pipeline = artifact.pipelines.find(pipeline => pipeline.name === 'deploy')!
        return {
            pipeline,
            artifact,
        }
    })

    const MOCK_SERVICES = [
        { name: 'PostgreSQL server', image: 'postgres' },
        { name: 'Kibana', image: 'docker.elastic.co/kibana/kibana:7.2.0' },
        { name: 'Mosquitto', image: 'eclipse-mosquitto' },
    ].map(item => ({
        ...item,
        jenkinsUrl: null,
        siteUrl: null,
        containerPort: null,
        publicPort: null,
        pipelines: [],
        status: ToolStatus.READY,
    }))

    return (
        <>
            <Grid container className={classes.root} spacing={4} justify="center">
                <CurrentWorkspaceSectionPaper
                    title="Artifacts"
                    tabsColor="#ffeded"
                    values={state.artifacts}
                    currentValue={state.selectedArtifact}
                    toOption={convertArtifactToOption}
                    onSelect={selectArtifact}
                    createButtonLabel="Add new artifact"
                    onCreateRequest={openArtifactDialog}
                >
                    {state.selectedArtifact && (
                        <>
                            <ArtifactDetails
                                artifact={state.selectedArtifact}
                                workspaceName={appState.currentWorkspace.name}
                                onBuildTrigger={startBuildSelector}
                            />
                        </>
                    )}
                </CurrentWorkspaceSectionPaper>

                <CurrentWorkspaceSectionPaper
                    title="Tools"
                    tabsColor="#e9f0f7"
                    values={state.workspace.tools}
                    currentValue={state.selectedTool}
                    toOption={convertToolToOption}
                    onSelect={selectTool}
                    createButtonLabel="Add new tool"
                    onCreateRequest={openToolDialog}
                >
                    {state.selectedTool && (
                        <ToolDetails tool={state.selectedTool} onBuildTrigger={startToolBuild} />
                    )}
                </CurrentWorkspaceSectionPaper>

                <CurrentWorkspaceSectionPaper
                    title="Environment"
                    tabsColor="#ddefe1"
                    values={state.workspace.environments}
                    currentValue={state.selectedEnvironment}
                    toOption={convertEnvironmentToOption}
                    onSelect={selectEnvironment}
                    createButtonLabel="Add new environment"
                    onCreateRequest={() => openEnvironmentDialog()}
                >
                    {state.selectedEnvironment && (
                        <Grid item xs={12}>
                            <EnvironmentDetails
                                environment={state.selectedEnvironment}
                                pipelines={deploymentPipelines}
                                onEditRequest={openEnvironmentDialog}
                                onDeployRequest={deployEnvironment}
                            />
                        </Grid>
                    )}
                </CurrentWorkspaceSectionPaper>
            </Grid>

            <AddArtifactDialog
                isOpen={state.isAddArtifactDialogOpen}
                isBusy={state.isAddingArtifact}
                onCloseRequest={closeDialogs}
                onConfirm={createArtifact}
            />

            <ReleaseParameterDialog
                isOpen={state.isReleaseParameterDialogOpen}
                isBusy={state.isReleasingArtifact}
                lastVersion={
                    state.selectedArtifact && state.selectedArtifact.releases.length > 0 
                    ? state.selectedArtifact.releases[state.selectedArtifact!.releases.length - 1].versionCode 
                    : '0.0.0'
                }
                onCloseRequest={closeDialogs}
                onConfirm={startParameterizedBuild}
            />

            <BuildParameterDialog
                isOpen={state.isBuildParameterDialogOpen}
                isBusy={state.isBuildingArtifact}
                lastVersion={
                    state.selectedArtifact && state.selectedArtifact.builds.length > 0 
                    ? state.selectedArtifact.builds[state.selectedArtifact!.builds.length - 1].versionCode 
                    : '0.0.0'
                }
                onCloseRequest={closeDialogs}
                onConfirm={startParameterizedBuild}
            />
            <DeployParameterDialog
                isOpen={state.isDeployParameterDialogOpen}
                isBusy={state.isDeployingArtifact}
                environments={state.workspace.environments}
                // TODO Find better alternative to make sure that a valid release is passed
                releases={state.selectedArtifact ? [...state.selectedArtifact.releases, ...state.selectedArtifact.builds] : [{ versionCode: 'latest', major: '', minor: '', fix: '', created: ''}]}
                onCloseRequest={closeDialogs}
                onConfirm={startParameterizedBuild}
            />

            <AddOrEditToolDialog
                isOpen={state.isAddToolDialogOpen}
                isBusy={state.isAddingTool}
                onCloseRequest={closeDialogs}
                onConfirm={createTool}
            />

            <AddOrEditEnvironmentDialog
                isOpen={state.isEnvironmentDialogOpen}
                isBusy={state.isProcessingEnvironment}
                artifacts={state.artifacts}
                environment={state.currentEnvironment}
                onCloseRequest={closeDialogs}
                onConfirm={createOrUpdateEnvironment}
            />

        </>
    )
}

export default CurrentWorkspaceScreen
