import SignInError, { SignInErrorType } from './exceptions/SignInError'
import handleAuthError from '../../common/data/handleAuthError'
import { TokenStorage } from './TokenStorage'

interface JwtTokenResponse {
    jwtToken: string
}

interface Credentials {
    user: string
    password: string
}

export default class SignInApi {
    private baseUrl: string

    constructor(baseUrl: string) {
        this.baseUrl = baseUrl
    }

    signIn(credentials: Credentials): Promise<JwtTokenResponse> {
        return fetch(this.baseUrl + '/account/signin', {
            method: 'POST',
            body: JSON.stringify(credentials),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(handleAuthError(TokenStorage.getInstance(), false))
            .then(response => response.text())
            .then(data => JSON.parse(data))
            .catch(() => {
                throw new SignInError(SignInErrorType.GENERIC)
            })
    }
}
