import { Option, OptionStatus } from '../../common/presentation/components/EntitySelectorTabs'
import Environment from '../../workspaces/data/entities/Environment'

const convertEnvironmentToOption = (environment: Environment): Option => {
    const name = environment.name
    const status = OptionStatus.SUCCESS
    return {
        name,
        status,
    }
}

export default convertEnvironmentToOption
