import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import WorkspacesApi from '../data/WorkspacesApi'
import Workspace from '../data/entities/Workspace'
import Grid from '@material-ui/core/Grid/Grid'
import { TokenStorage } from '../../signin/data/TokenStorage'
import CircularProgressCentered from '../../common/presentation/components/CircularProgressCentered/CircularProgressCentered'
import Workspaces from './components/Workspaces'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { __RouterContext } from 'react-router'
import { useAppState } from '../../common/presentation/AppStateContext'
import AppActionType from '../../AppActionType'
import { Fab } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import AddOrEditWorkspaceDialog from './components/AddWorkspacesDialog/AddOrEditWorkspaceDialog'
import NoWorkspaceHint from './components/NoWorkspaceHint'
import WorkspaceDraft from './components/AddWorkspacesDialog/WorkspaceDraft'
import Artifact from '../../currentworkspace/data/entities/Artifact'
import ArtifactsApi from '../../currentworkspace/data/ArtifactsApi'
import useInterval from '../../common/presentation/useInterval'

interface State {
    workspaces: Array<Workspace> | null
    artifacts: Array<Artifact> | null
    currentWorkspace: Workspace | null
    isWorkspaceDialogOpen: boolean
    isAddingWorkspace: boolean
    workspacesBeingDeleted: Array<Workspace>
}

const DEFAULT_STATE = {
    workspaces: null,
    artifacts: null,
    currentWorkspace: null,
    isWorkspaceDialogOpen: false,
    isAddingWorkspace: false,
    workspacesBeingDeleted: [],
}

const workspacesApi = new WorkspacesApi(
    process.env.REACT_APP_MIDDLEWARE_BASE_URL!,
    TokenStorage.getInstance()
)

const artifactsApi = new ArtifactsApi(
    process.env.REACT_APP_MIDDLEWARE_BASE_URL!,
    TokenStorage.getInstance()
)

const useStyles = makeStyles({
    root: {
        padding: 32,
    },
    fab: {
        position: 'absolute',
        right: 32,
        bottom: 32,
    },
})

function AddWorkspaceButton(props: any) {
    return (
        <Fab
            color="primary"
            size="large"
            className={props.classes.fab}
            onClick={() => props.onOpenDialogRequest(null)}
        >
            <AddIcon />
        </Fab>
    )
}

function WorkspacesScreen() {
    const [state, setState] = useState<State>({ ...DEFAULT_STATE })
    const routerContext = useContext(__RouterContext)
    const [appState, dispatch] = useAppState()

    const classes = useStyles()

    useEffect(() => {
        workspacesApi
            .fetchAll()
            .then(workspaces => setState(prevState => ({ ...prevState, workspaces })))
        artifactsApi
            .fetchAll()
            .then(artifacts => setState(prevState => ({ ...prevState, artifacts })))
    }, [])

    useInterval(() => {
        workspacesApi.fetchAllEventsInWorkspace(null).then(updatedWorkspaces => {
            setState((prevState: any) => {
                const { workspaces } = prevState

                if (!workspaces && updatedWorkspaces.length) {
                    return { ...prevState, workspaces: updatedWorkspaces }
                }

                const joinedUpdatedWorkspaces = workspaces!.map((workspace: Workspace) => {
                    const updatedWorkspaceFromServerWithSameId = updatedWorkspaces.find(
                        newWorkspace => newWorkspace.id === workspace.id
                    )
                    if (updatedWorkspaceFromServerWithSameId)
                        return updatedWorkspaceFromServerWithSameId

                    return workspace
                })

                return {
                    ...prevState,
                    workspaces: joinedUpdatedWorkspaces,
                }
            })
        })
    }, 10000)

    if (!state.workspaces || !state.artifacts) {
        return <CircularProgressCentered />
    }

    const selectWorkspace = (workspace: Workspace) => {
        setState({ ...state, currentWorkspace: workspace })
        dispatch({ type: AppActionType.USE_WORKSPACE, workspace })
        setTimeout(() => routerContext.history.push('/current-workspace'), 500)
    }

    const onOpenDialogRequest = (workspace: Workspace | null) => {
        setState({ ...state, currentWorkspace: workspace, isWorkspaceDialogOpen: true })
    }

    const deleteWorkspace = async (workspace: Workspace) => {
        setState({ ...state, workspacesBeingDeleted: [...state.workspacesBeingDeleted, workspace] })

        if (appState.currentWorkspace && appState.currentWorkspace.id === workspace.id) {
            dispatch({ type: AppActionType.USE_WORKSPACE, workspace: null })
        }

        await workspacesApi.delete(workspace)

        const workspaceIndex = state.workspacesBeingDeleted.findIndex(ws => ws.id === workspace.id)
        const updatedWorkspacesBeingDeleted = state.workspacesBeingDeleted.splice(workspaceIndex, 1)

        await workspacesApi.fetchAll().then(workspaces =>
            setState(prevState => ({
                ...prevState,
                workspaces,
                workspacesBeingDeleted: updatedWorkspacesBeingDeleted,
            }))
        )
    }

    const onCloseDialogRequest = () => setState({ ...state, isWorkspaceDialogOpen: false })
    const onAddDialogConfirm = async (workspace: WorkspaceDraft) => {
        setState({ ...state, isAddingWorkspace: true })
        try {
            const newWorkspace = await workspacesApi.createNew(workspace)
            // const wait = new Promise(resolve => setTimeout(resolve, 1000))
            const fetchedWorkspaces = state.workspaces || []
            fetchedWorkspaces.push(newWorkspace)

            setState({
                ...state,
                workspaces: fetchedWorkspaces,
                isAddingWorkspace: false,
                isWorkspaceDialogOpen: false,
            })
        } catch (error) {
            setState({
                ...state,
                isAddingWorkspace: false,
            })
            alert('TODO handle error: ' + JSON.stringify(error))
        }
    }

    return (
        <>
            {!state.workspaces.length ? (
                <NoWorkspaceHint />
            ) : (
                <Grid container className={classes.root}>
                    <Grid item xs={12}>
                        <Workspaces
                            workspaces={state.workspaces}
                            workspacesBeingDeleted={state.workspacesBeingDeleted}
                            onSelect={selectWorkspace}
                            onEditRequest={onOpenDialogRequest}
                            onDeleteRequest={deleteWorkspace}
                            currentWorkspace={appState.currentWorkspace}
                        />
                    </Grid>
                </Grid>
            )}
            <AddWorkspaceButton classes={classes} onOpenDialogRequest={onOpenDialogRequest} />

            <AddOrEditWorkspaceDialog
                artifacts={state.artifacts}
                workspace={state.currentWorkspace}
                isOpen={state.isWorkspaceDialogOpen}
                isBusy={state.isAddingWorkspace}
                onCloseRequest={onCloseDialogRequest}
                onConfirm={onAddDialogConfirm}
            />
        </>
    )
}

export default WorkspacesScreen
