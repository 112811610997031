import moment from 'moment'

export function formatDateTime(dateTimeRaw: number | string) {
    if (typeof dateTimeRaw === 'string') {
        dateTimeRaw = Date.parse(dateTimeRaw)
    }

    const dateTime = new Date(dateTimeRaw)
    return moment(dateTime).format('MMM D, h:mm a')
}
