import * as React from 'react'
import {
    Avatar,
    Checkbox,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles,
    Typography,
} from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import Artifact from '../../../../currentworkspace/data/entities/Artifact'
import hintStyle from './hintStyle'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    avatar: {
        backgroundColor: red[500],
    },
    hint: hintStyle,
}))

interface Props {
    artifacts: Array<Artifact>
    selectedArtifactIds: Array<string>
    onSelectionChange: (newSelectedArtifactIs: Array<string>) => void
}

function ArtifactsSelector({ artifacts, selectedArtifactIds, onSelectionChange }: Props) {
    const classes = useStyles()

    const toggleArtifactSelection = (artifact: Artifact) => {
        const isArtifactAlreadySelected = selectedArtifactIds.includes(artifact.id)
        let newSelectedArtifactIds
        if (isArtifactAlreadySelected) {
            newSelectedArtifactIds = selectedArtifactIds.filter(
                artifactId => artifactId !== artifact.id
            )
        } else {
            newSelectedArtifactIds = [...selectedArtifactIds, artifact.id]
        }

        onSelectionChange(newSelectedArtifactIds)
    }

    return (
        <>
            <Typography variant="body2" className={classes.hint}>
                Select artifacts to use in the new workspace. You can configure concrete
                combinations of tagged artifacts later in this dialog as environments.
            </Typography>
            <List dense className={classes.root}>
                {artifacts.map(artifact => {
                    return (
                        <ListItem
                            key={artifact.id}
                            button
                            onClick={() => toggleArtifactSelection(artifact)}
                        >
                            <ListItemAvatar>
                                <Avatar className={classes.avatar}>
                                    {artifact.name[0].toUpperCase()}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={artifact.name}
                                secondary={
                                    <React.Fragment>
                                        <Typography component="span" variant="body2">
                                            A fact about the artifact
                                            {/*TODO*/}
                                        </Typography>
                                    </React.Fragment>
                                }
                            />
                            <ListItemSecondaryAction>
                                <Checkbox
                                    edge="end"
                                    color="primary"
                                    onChange={() => toggleArtifactSelection(artifact)}
                                    checked={selectedArtifactIds.includes(artifact.id)}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                    )
                })}
            </List>
        </>
    )
}

export default ArtifactsSelector
