import Environment from '../../workspaces/data/entities/Environment'

const restoreEnvironment = (
    previouslySelectedEnvironment: Environment | null,
    newlyLoadedEnvironments: Array<Environment>
) => {
    if (previouslySelectedEnvironment === null) {
        if (newlyLoadedEnvironments.length) {
            return newlyLoadedEnvironments[0]
        }

        return null
    }

    const environment = newlyLoadedEnvironments.find(
        newTool => newTool.id === previouslySelectedEnvironment!.id
    )
    return environment || null
}

export default restoreEnvironment
