import Artifact from '../data/entities/Artifact'

export default function restoreArtifact(
    updatedArtifacts: Array<Artifact>,
    currentArtifact: Artifact | null
) {
    if (!currentArtifact) {
        return null
    }

    const newCurrentArtifact = updatedArtifacts.find(artifact => artifact.id === currentArtifact.id)
    if (!newCurrentArtifact) {
        return null
    }

    return newCurrentArtifact
}
