import moment from 'moment'

const FULL_DAY_TIME_FORMAT = 'MMM D, h:mm a'
const TIME_FORMAT = 'h:mm a'

export function formatTimeSpan(startTimeMillis: number, endTimeMillis: number) {
    const startTime = new Date(startTimeMillis)
    const endTime = new Date(endTimeMillis)

    const formattedStartTime = moment(startTime).format(FULL_DAY_TIME_FORMAT)

    const areDatesOnSameDay =
        startTime.getDay() === endTime.getDay() && startTime.getMonth() === endTime.getMonth()

    const endTimeFormat = areDatesOnSameDay ? TIME_FORMAT : FULL_DAY_TIME_FORMAT
    const formattedEndTime = moment(endTime).format(endTimeFormat)

    return `${formattedStartTime} - ${formattedEndTime}`
}
