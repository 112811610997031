import * as React from 'react'
import { useEffect, useState } from 'react'
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Environment from '../../../data/entities/Environment'
import Artifact from '../../../../currentworkspace/data/entities/Artifact'

interface Props {
    isOpen: boolean
    isBusy: boolean
    artifacts: Array<Artifact>
    environment: Environment | null
    onCloseRequest: () => void
    onConfirm: (environment: Environment, previousName: string) => void
}

interface State {
    environment: Environment
    isEdit: boolean
    previousName: string
}

const DEFAULT_STATE = {
    environment: { id: '', name: '', artifactReleasesByArtifactIds: {}, artifactDatasetByArtifactIds: {} },
    isEdit: false,
    previousName: '',
}

const useStyles = makeStyles({
    progress: {
        marginRight: 10,
    },
    artifactName: {
        fontSize: 16,
        marginTop: 19,
        marginRight: 32,
    },
})

const DATASETS = [
    {label: 'Minimal test data', value: 'minimal'},
    {label: 'Test data for integration test', value: 'integration'},
    {label: 'Copy of production data', value: 'production'},
]

function AddOrEditEnvironmentDialog({
    isOpen,
    isBusy,
    artifacts,
    environment,
    onConfirm,
    onCloseRequest,
}: Props) {
    const [state, setState] = useState<State>({ ...DEFAULT_STATE })
    const classes = useStyles()

    useEffect(() => {
        const isEdit = !!environment
        const givenEnvironment = isEdit
            ? environment!
            : { name: '', artifactReleasesByArtifactIds: {}, artifactDatasetByArtifactIds: {} }
        // TODO load real data once available
        givenEnvironment.artifactDatasetByArtifactIds = {};
        artifacts.map(artifact => {
            givenEnvironment.artifactDatasetByArtifactIds[artifact.id] = ''
        })

        setState(prevState => ({
            ...prevState,
            environment: givenEnvironment,
            isEdit,
            previousName: givenEnvironment.name,
        }))
    }, [isOpen, environment])

    const onNameChange = (name: string) => {
        setState(prevState => ({ ...prevState, environment: { ...state.environment, name } }))
    }

    // @ts-ignore
    return (
        <Dialog open={isOpen} onClose={onCloseRequest}>
            <DialogTitle>{environment ? 'Edit' : 'Add'} environment</DialogTitle>
            <DialogContent>
                <TextField
                    style={{ marginBottom: 16 }}
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Environment name"
                    type="text"
                    fullWidth
                    autoComplete="off"
                    onChange={evt => onNameChange(evt.target.value)}
                    value={state.environment.name}
                    disabled={isBusy}
                />

                <Grid container>
                    {artifacts.map(artifact => {
                        const onVersionChange = (evt: any) => {
                            const selectedVersionCode = evt.target.value
                            const { environment } = state
                            environment.artifactReleasesByArtifactIds[
                                artifact.id
                            ] = selectedVersionCode

                            setState({ ...state, environment })
                        }

                        const onDatasetChange = (evt: any) => {
                            const selectedDataset = evt.target.value
                            const { environment } = state
                            environment.artifactDatasetByArtifactIds[
                                artifact.id
                            ] = selectedDataset

                            setState({ ...state, environment })
                        }

                        const currentValue = state.environment
                            ? state.environment.artifactReleasesByArtifactIds[artifact.id] || ''
                            : ''

                        const currentDataset = state.environment
                            ? state.environment.artifactDatasetByArtifactIds[artifact.id] || ''
                            : ''
                        
                        const artifactReleasesAndBuilds = [ ...artifact.releases, ...artifact.builds]

                        return (
                            <>
                                <Grid item xs={4}>
                                    <Typography variant="h6" className={classes.artifactName}>
                                        {artifact.name}
                                    </Typography>
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControl>
                                        <InputLabel shrink>Version</InputLabel>
                                        <Select
                                            style={{ width: 140 }}
                                            value={currentValue}
                                            onChange={onVersionChange}
                                            input={<Input name="version" />}
                                            displayEmpty
                                            name="version"
                                            disabled={isBusy}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>

                                            {artifactReleasesAndBuilds.map(release => (
                                                <MenuItem value={release.versionCode}>
                                                    {release.versionCode}
                                                </MenuItem>
                                            ))}

                                            {artifact.releases.length && (
                                                <MenuItem value="latest">Latest</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl>
                                        <InputLabel shrink>Dataset</InputLabel>
                                        <Select
                                            style={{ width: 140 }}
                                            value={currentDataset}
                                            onChange={onDatasetChange}
                                            input={<Input name="dataset" />}
                                            displayEmpty
                                            name="dataset"
                                            disabled={isBusy}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>

                                            {DATASETS.map(dataset => (
                                                <MenuItem value={dataset.value}>
                                                    {dataset.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {
                                    // TODO Add dataset selector
                                }

                            </>
                        )
                    })}
                    {
                        // TODO Add artifact selector from other workspaces
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseRequest}>Cancel</Button>
                <Button
                    onClick={() => onConfirm(state.environment, state.previousName)}
                    color="primary"
                    disabled={state.environment.name.length === 0}
                >
                    {isBusy && <CircularProgress className={classes.progress} size={16} />}
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddOrEditEnvironmentDialog
