import * as React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Card, CardContent, Typography } from '@material-ui/core'

const useStyles = makeStyles({
    card: {
        maxWidth: 275,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 100,
    },
})

function NoWorkspaceHint() {
    const classes = useStyles()

    return (
        <Card className={classes.card}>
            <CardContent>
                <Typography variant="h6">No workspaces.</Typography>
                <Typography variant="body2" component="p">
                    You can simply create a new workspace by using the button on the bottom right.
                </Typography>
            </CardContent>
        </Card>
    )
}

export default NoWorkspaceHint
