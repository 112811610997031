import * as React from 'react'
import { useState } from 'react'
import {deepOrange, green, grey, red} from '@material-ui/core/colors'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Execution, { ExecutionStatus } from '../../data/entities/Execution'
import { formatDateTime } from '../../../common/presentation/formatDateTime'
import { formatDurationToMinutes } from '../../../common/presentation/formatDurationToMinutes'
import { Button, ClickAwayListener, Fade, Paper, Popper } from '@material-ui/core'
import HistoryIcon from '@material-ui/icons/History'

interface Props {
    executions: Array<Execution>
}

interface State {
    isExecutionHistoryExpanded: boolean
    anchorEl: HTMLElement | null
}

const DEFAULT_STATE = {
    isExecutionHistoryExpanded: false,
    anchorEl: null,
}

const MARKER_BASE_STYLE = {
    display: 'inline-block',
    width: 10,
    height: 10,
    marginRight: 12,
    borderRadius: '100%',
}

const useStyles = makeStyles({
    button: {
        marginTop: 16,
    },
    popperPaper: {
        padding: 16,
    },
    list: {
        listStyle: 'none',
        margin: 0,
        padding: 0,
    },
    execution: {
        marginBottom: 4,
    },
    executionName: {
        fontWeight: 'bold',
        fontSize: 12,
        marginRight: 4,
    },

    executionDetails: {
        color: grey[600],
        fontSize: 12,
    },

    errorMarker: {
        ...MARKER_BASE_STYLE,
        backgroundColor: red[500],
    },
    successMarker: {
        ...MARKER_BASE_STYLE,
        backgroundColor: green[500],
    },
    pendingMarker: {
        ...MARKER_BASE_STYLE,
        backgroundColor: grey[500],
    },
    warningMarker: {
        ...MARKER_BASE_STYLE,
        backgroundColor: deepOrange[500],
    },
    abortedMarker: {
        ...MARKER_BASE_STYLE,
        backgroundColor: grey[500],
    },
    historyIcon: {
        marginRight: 8,
    },
})

interface ExecutionStatusAvatarProps {
    execution: Execution
    classes: Record<any, any>
}

const ExecutionStatusMarker = ({ execution, classes }: ExecutionStatusAvatarProps) => {
    const className = classes[CLASS_NAMES_BY_STATUS[execution.status]]
    return <div className={className}></div>
}

const CLASS_NAMES_BY_STATUS = {
    SUCCESS: 'successMarker',
    NOT_EXECUTED: 'errorMarker',
    IN_PROGRESS: 'pendingMarker',
    FAILED: 'errorMarker',
    ABORTED: 'abortedMarker',
    UNSTABLE: 'warningMarker',
}

function PastExecutions({ executions }: Props) {
    const classes = useStyles()
    const [state, setState] = useState<State>({ ...DEFAULT_STATE })

    const createExecutionDetails = (execution: Execution) => {
        const startTimeFormatted = formatDateTime(execution.startTimeMillis)
        const durationMinutes = formatDurationToMinutes(execution.durationMillis)
        const pending = execution.status === ExecutionStatus.IN_PROGRESS ? 'pending - ' : ''
        return `${pending}started ${startTimeFormatted}, ${durationMinutes} min`
    }

    const onButtonClick = (event: React.MouseEvent<HTMLElement>) => {
        setState(prevState => ({
            ...prevState,
            anchorEl: !prevState.anchorEl ? event.currentTarget : null,
        }))
    }

    const onOutsideClick = () => setState(prevState => ({ ...prevState, anchorEl: null }))

    const open = Boolean(state.anchorEl)
    const id = open ? 'simple-popper' : undefined

    return (
        <>
            <ClickAwayListener onClickAway={onOutsideClick}>
                <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    className={classes.button}
                    onClick={onButtonClick}
                >
                    <HistoryIcon className={classes.historyIcon} />
                    {executions.length} previous executions
                </Button>
            </ClickAwayListener>

            <Popper id={id} open={open} anchorEl={state.anchorEl} transition>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={450}>
                        <Paper elevation={10} className={classes.popperPaper}>
                            <ul className={classes.list}>
                                {executions.map(execution => {
                                    return (
                                        <li key={execution.name} className={classes.execution}>
                                            <ExecutionStatusMarker
                                                execution={execution}
                                                classes={classes}
                                            />

                                            <span className={classes.executionName}>
                                                {execution.name}
                                            </span>
                                            <span className={classes.executionDetails}>
                                                {createExecutionDetails(execution)}
                                            </span>
                                        </li>
                                    )
                                })}
                            </ul>
                        </Paper>
                    </Fade>
                )}
            </Popper>
        </>
    )
}

export default PastExecutions
