import React from 'react'
import CurrentWorkspaceScreen from '../../../currentworkspace/presentation/CurrentWorkspaceScreen'
import { Route } from 'react-router'
import SignInScreen from '../../../signin/presentation/SignInScreen'
import ProtectedRoute from './ProtectedRoute'
import WorkspacesScreen from '../../../workspaces/presentation/WorkspacesScreen'
import { useAppState } from '../AppStateContext'

function MainContent() {
    const [appState] = useAppState()
    const isSignedIn = appState.jwtToken !== null

    return (
        <main className={isSignedIn ? 'user-signed-in' : ''}>
            <Route exact path={'/'} component={SignInScreen} />
            <ProtectedRoute exact path={'/workspaces'} component={WorkspacesScreen} />
            <ProtectedRoute exact path={'/current-workspace'} component={CurrentWorkspaceScreen} />
        </main>
    )
}

export default MainContent
