export enum StageStatus {
    SUCCESS = 'SUCCESS',
    IN_PROGRESS = 'IN_PROGRESS',
    FAILED = 'FAILED',
	ABORTED = 'ABORTED',
    UNSTABLE = 'UNSTABLE'    
}

export default interface Stage {
    name: string
    status: StageStatus
    startTimeMillis: number
    durationMillis: number
}
