import * as React from 'react'
import { ChangeEvent, useState } from 'react'
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
} from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Tool, { ToolStatus } from '../../../data/entities/Tool'
import ToolGrid from './ToolGrid'
import ToolOption from './ToolOption'

interface Props {
    isOpen: boolean
    isBusy: boolean
    onCloseRequest: () => void
    onConfirm: (tool: Tool) => void
}

interface State {
    name: string
    image: string
    containerPort: string
    publicPort: string
}

const DEFAULT_STATE = {
    name: '',
    image: '',
    containerPort: '',
    publicPort: '',
}

const useStyles = makeStyles({
    content: {
        overflow: 'hidden',
    },
    progress: {
        marginRight: 10,
    },
})

function AddOrEditToolDialog({ isOpen, isBusy, onConfirm, onCloseRequest }: Props) {
    const [state, setState] = useState<State>({ ...DEFAULT_STATE })
    const classes = useStyles()

    const onNameChange = (event: ChangeEvent<HTMLInputElement>) =>
        setState({ ...state, name: event.currentTarget.value })
    const onImageUrlChange = (event: ChangeEvent<HTMLInputElement>) =>
        setState({ ...state, image: event.currentTarget.value })
    const onContainerPortChange = (event: ChangeEvent<HTMLInputElement>) =>
        setState({ ...state, containerPort: event.currentTarget.value })
    const onPublicPortChange = (event: ChangeEvent<HTMLInputElement>) =>
        setState({ ...state, publicPort: event.currentTarget.value })
    const onToolSelect = (tool: ToolOption) => {
        setState({
            name: tool.name,
            image: tool.image,
            containerPort: tool.containerPort,
            publicPort: tool.publicPort,
        })
    }

    return (
        <Dialog open={isOpen} onClose={onCloseRequest} maxWidth="lg">
            <DialogTitle>Add new tool</DialogTitle>
            <DialogContent className={classes.content}>
                <Grid container spacing={3}>
                    <Grid item xs={8}>
                        <ToolGrid onSelect={onToolSelect} />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Tool name"
                            type="text"
                            autoComplete="off"
                            value={state.name}
                            onChange={onNameChange}
                            disabled={isBusy}
                            fullWidth
                        />

                        <TextField
                            margin="dense"
                            label="Docker image"
                            type="text"
                            autoComplete="off"
                            value={state.image}
                            onChange={onImageUrlChange}
                            disabled={isBusy}
                            fullWidth
                        />

                        <TextField
                            margin="dense"
                            label="Container port"
                            type="number"
                            autoComplete="off"
                            value={state.containerPort}
                            onChange={onContainerPortChange}
                            disabled={isBusy}
                            fullWidth
                        />

                        <TextField
                            margin="dense"
                            label="Public port"
                            type="number"
                            autoComplete="off"
                            value={state.publicPort}
                            onChange={onPublicPortChange}
                            disabled={isBusy}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseRequest}>Cancel</Button>
                <Button
                    onClick={() =>
                        onConfirm({
                            name: state.name,
                            image: state.image,
                            status: ToolStatus.NOT_STARTED,
                            containerPort: state.containerPort,
                            publicPort: state.publicPort,
                            siteUrl: null,
                            jenkinsUrl: null,
                            pipelines: [],
                        })
                    }
                    color="primary"
                    disabled={state.name.length === 0 && state.image.length === 0}
                >
                    <CircularProgress
                        className={classes.progress}
                        style={{ opacity: isBusy ? 1 : 0 }}
                        size={16}
                    />
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddOrEditToolDialog
