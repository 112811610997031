import { Option, OptionStatus } from '../../common/presentation/components/EntitySelectorTabs'
import Tool, { ToolStatus } from '../../workspaces/data/entities/Tool'

const convertToolToOption = (tool: Tool): Option => {
    const name = tool.name
    let status
    switch (tool.status) {
        case ToolStatus.READY:
            status = OptionStatus.SUCCESS
            break
        case ToolStatus.IN_PROGRESS:
        case ToolStatus.NOT_STARTED:
            status = OptionStatus.PENDING
            break
        case ToolStatus.ERROR:
            status = OptionStatus.ERROR
            break
        default:
            throw new Error('Unknown status: ' + status)
    }

    return {
        name,
        status,
    }
}

export default convertToolToOption
