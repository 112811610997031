import * as React from 'react'
import { ButtonBase, Grid, Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import ToolOption from './ToolOption'
import CreateIcon from '@material-ui/icons/Create'
import elasticSearchLogo from '../../../../currentworkspace/presentation/assets/elasticsearch.svg'
import logstashLogo from '../../../../currentworkspace/presentation/assets/logstash.svg'
import kibanaLogo from '../../../../currentworkspace/presentation/assets/kibana.svg'
import graphiteLogo from '../../../../currentworkspace/presentation/assets/graphite.png'
import grafanaLogo from '../../../../currentworkspace/presentation/assets/grafana.svg'
import statsdLogo from '../../../../currentworkspace/presentation/assets/statsd.png'
import prometheusLogo from '../../../../currentworkspace/presentation/assets/prometheus.png'
import archivaLogo from '../../../../currentworkspace/presentation/assets/archiva.png'
import { grey } from '@material-ui/core/colors'

interface Props {
    onSelect: (opt: ToolOption) => void
}

const useStyles = makeStyles({
    option: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        boxSizing: 'border-box',
        padding: 28,
    },
    optionName: {
        fontSize: 18,
        fontWeight: 600,
        color: grey[700],
    },
    optionImage: {
        fontSize: 15,
        lineHeight: '15px',
        color: grey[500],
    },
    defaultLogo: { width: 64, height: 64, fill: grey[500], marginBottom: 12 },
    logo: { height: 64, marginBottom: 12 },
})

const TOOL_OPTIONS = [
    {
        name: 'Grafana',
        image: 'grafana/grafana',
        logo: grafanaLogo,
        containerPort: '80',
        publicPort: '80',
    },
    {
        name: 'Kibana',
        image: 'kibana',
        logo: kibanaLogo,
        containerPort: '80',
        publicPort: '80',
    },
    {
        name: 'Elasticsearch',
        image: 'elasticsearch',
        logo: elasticSearchLogo,
        containerPort: '80',
        publicPort: '80',
    },
    {
        name: 'Logstash',
        image: 'logstash',
        logo: logstashLogo,
        containerPort: '80',
        publicPort: '80',
    },
    {
        name: 'Graphite',
        image: 'graphiteapp/graphite-statsd',
        logo: graphiteLogo,
        containerPort: '80',
        publicPort: '80',
    },
    {
        name: 'statsd',
        image: 'statsd/statsd',
        logo: statsdLogo,
        containerPort: '80',
        publicPort: '80',
    },
    {
        name: 'Prometheus',
        image: 'prom/prometheus',
        logo: prometheusLogo,
        containerPort: '80',
        publicPort: '80',
    },
    {
        name: 'Archiva',
        image: 'xetusoss/archiva\n',
        logo: archivaLogo,
        containerPort: '80',
        publicPort: '80',
    },
]

const CUSTOM_OPTION = {
    name: '',
    image: '',
    logo: '',
    containerPort: '',
    publicPort: '',
}

function ToolGrid(props: Props) {
    const classes = useStyles()

    const options = TOOL_OPTIONS

    return (
        <Grid container>
            <Grid item xs={4}>
                <ButtonBase
                    focusRipple
                    className={classes.option}
                    onClick={() => props.onSelect(CUSTOM_OPTION)}
                >
                    <CreateIcon className={classes.defaultLogo} />
                    <Typography variant="h6" className={classes.optionName}>
                        Custom tool
                    </Typography>
                </ButtonBase>
            </Grid>

            {options.map(option => (
                <Grid item xs={4}>
                    <ButtonBase
                        focusRipple
                        // key={image.title}
                        className={classes.option}
                        onClick={() => props.onSelect(option)}
                    >
                        <img src={option.logo} className={classes.logo} />
                        <Typography variant="h6" className={classes.optionName}>
                            {option.name}
                        </Typography>
                        <Typography variant="subtitle1" className={classes.optionImage}>
                            {option.image}
                        </Typography>
                    </ButtonBase>
                </Grid>
            ))}
        </Grid>
    )
}

export default ToolGrid
