import Workspace from './entities/Workspace'
import { TokenStorage } from '../../signin/data/TokenStorage'
import handleAuthError from '../../common/data/handleAuthError'
import createAuthHeader from '../../common/data/createRequestHeader'
import WorkspaceDraft from '../presentation/components/AddWorkspacesDialog/WorkspaceDraft'

export default class WorkspacesApi {
    private baseUrl: string
    private tokenStorage: TokenStorage

    constructor(baseUrl: string, tokenStorage: TokenStorage) {
        this.baseUrl = baseUrl
        this.tokenStorage = tokenStorage
    }

    fetchAll(): Promise<Array<Workspace>> {
        return fetch(this.baseUrl + '/workspaces', {
            headers: createAuthHeader(this.tokenStorage),
        })
            .then(handleAuthError(this.tokenStorage))
            .then(response => response.text())
            .then(data => JSON.parse(data))
    }

    createNew(draft: WorkspaceDraft): Promise<Workspace> {
        return fetch(this.baseUrl + '/workspaces', {
            method: 'POST',
            headers: createAuthHeader(this.tokenStorage),
            body: JSON.stringify(draft),
        })
            .then(handleAuthError(this.tokenStorage))
            .then(response => response.text())
            .then(data => JSON.parse(data))
    }

    update(workspace: Workspace): Promise<Workspace> {
        return fetch(this.baseUrl + '/workspaces', {
            method: 'PUT',
            headers: createAuthHeader(this.tokenStorage),
            body: JSON.stringify(workspace),
        })
            .then(handleAuthError(this.tokenStorage))
            .then(response => response.text())
            .then(data => JSON.parse(data))
    }

    delete(workspace: Workspace): Promise<Workspace> {
        return fetch(this.baseUrl + '/workspaces', {
            method: 'DELETE',
            headers: createAuthHeader(this.tokenStorage),
            body: JSON.stringify(workspace),
        })
            .then(handleAuthError(this.tokenStorage))
            .then(response => response.text())
            .then(data => JSON.parse(data))
    }

    fetchAllEventsInWorkspace(workspace: Workspace | null): Promise<Array<Workspace>> {
        const filterQuery = workspace ? `?workspaceId=${workspace.id}` : ''

        const controller = new AbortController();
        const id = setTimeout(() => controller.abort(), 8500);

        return fetch(`${this.baseUrl}/workspaces/events${filterQuery}`, 
                    {
                        headers: createAuthHeader(this.tokenStorage),
                        signal: controller.signal,
        })
            .then(handleAuthError(this.tokenStorage))
            .then(response => {  clearTimeout(id);
                                return response} )
            .then(response => response.text())
            .then(data => JSON.parse(data))
    }

    private createNewEventSource(workspace: Workspace | null) {
        const filterQuery = workspace ? `?workspaceId=${workspace.id}` : ''
        return new EventSource(`${this.baseUrl}/workspaces/events${filterQuery}`)
    }
}
