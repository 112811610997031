import * as React from 'react'
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Typography,
    Grid,
    InputLabel,
    Select,
    Input,
    MenuItem,
} from '@material-ui/core'
import { useState, useEffect } from 'react'
import { ChangeEvent } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import BuildParameter from '../../data/entities/BuildParameter'
import Environment from '../../../workspaces/data/entities/Environment'
import Release from '../../data/entities/Release'

interface Props {
    isOpen: boolean
    isBusy: boolean
    environments: Array<Environment>
    releases: Array<Release>
    onCloseRequest: () => void
    onConfirm: (parameters: BuildParameter) => void
}

interface State {
    environment: string
    release: string
}

const DEFAULT_STATE = {
    environment: '', // TODO Make sure to get the id of the default workspace
    release: 'latest',
}

const useStyles = makeStyles({
    progress: {
        marginRight: 10,
    },
    formControl: {
        marginBottom: 16,
    },
    label: {
        fontSize: 16,
        marginTop: 19,
        marginRight: 32,
    },
})


function DeployParameterDialog({ isOpen, isBusy, environments, releases, onConfirm, onCloseRequest }: Props) {
    const [state, setState] = useState<State>({ ...DEFAULT_STATE })
    const classes = useStyles()

    //useEffect(() => {
    //    if (environments.length > 0) {
    //        setState({ ...state, environment: environments[0].name! })
    //    }
    //}, [environments]);

    const onEnvironmentChange = (event: ChangeEvent<any>) => {
        setState({ ...state, environment: event.target.value })
    }

    const onReleaseChange = (event: ChangeEvent<any>) => {
        setState({ ...state, release: event.target.value })
    }

    const shouldDisableButton =
        state.environment.length === 0 || state.release.length === 0 || isBusy

    return (
        <Dialog open={isOpen} onClose={onCloseRequest} fullWidth={true}>
            <DialogTitle>Deploy artifact to environment</DialogTitle>
            <DialogContent>
                <Typography>Latest build: TBD</Typography>
                <Grid container>

                    <Grid item xs={8}>
                        <Typography variant="h6" className={classes.label}>
                            Environment
                        </Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <FormControl>
                            <InputLabel shrink>Environment</InputLabel>
                            <Select
                                style={{ width: 140 }}
                                value={state.environment}
                                onChange={onEnvironmentChange}
                                input={<Input name="environment" />}
                                displayEmpty
                                name="environment"
                                disabled={isBusy}
                            >

                                <MenuItem value="">None</MenuItem>
                                {environments.map(environment => (
                                    <MenuItem value={environment.name}>
                                        {environment.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={8}>
                        <Typography variant="h6" className={classes.label}>
                            Build version
                        </Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <FormControl>
                            <InputLabel shrink>Release</InputLabel>
                            <Select
                                style={{ width: 140 }}
                                value={state.release}
                                onChange={onReleaseChange}
                                input={<Input name="release" />}
                                displayEmpty
                                name="release"
                                disabled={isBusy}
                            >
                                {releases.map(release => (
                                    <MenuItem value={release.versionCode}>
                                        {release.versionCode}
                                    </MenuItem>
                                ))}

                                {releases.length && (
                                    <MenuItem value="latest">Latest</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseRequest}>Cancel</Button>
                <Button
                    onClick={() => onConfirm({
                        ENVIRONMENT: state.environment,
                        VERSION_NUMBER: state.release,
                    })}
                    color="primary"
                    disabled={shouldDisableButton}
                >
                    <CircularProgress
                        className={classes.progress}
                        style={{ opacity: isBusy ? 1 : 0 }}
                        size={16}
                    />
                    Deploy
                </Button>
            </DialogActions>
        </Dialog >
    )
}

export default DeployParameterDialog
