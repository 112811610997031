import * as React from 'react'
import {deepOrange, green, grey, red} from '@material-ui/core/colors'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Avatar from '@material-ui/core/Avatar/Avatar'
import { CircularProgress } from '@material-ui/core'
import Stage, { StageStatus } from '../../data/entities/Stage'
import { formatTimeSpan } from '../../../common/presentation/formatTimeSpan'
import { formatDurationToMinutes } from '../../../common/presentation/formatDurationToMinutes'
import WarningIcon from "@material-ui/icons/Warning";

interface Props {
    stages: Array<Stage>
}

const AVATAR_BASE_STYLE = {
    width: 20,
    height: 20,
    marginRight: 16,
}

const useStyles = makeStyles({
    root: {
        listStyle: 'none',
        paddingLeft: 16,
    },

    elementWrapper: {
        display: 'flex',
        marginBottom: 8,
    },

    detailsWrapper: {
        flex: 1,
        display: 'flex',
        lineHeight: '20px',
    },

    avatarIcon: {
        width: 12,
        height: 12,
    },

    elementTitle: {
        fontSize: 12,
        fontWeight: 600,
        color: grey[500],
        minWidth: '25%',
        maxWidth: '40%',
    },

    elementDetails: {
        fontSize: 12,
        color: grey[500],
        flex: 1,
        textAlign: 'right',
    },

    errorMarker: {
        ...AVATAR_BASE_STYLE,
        backgroundColor: red[500],
    },
    successMarker: {
        ...AVATAR_BASE_STYLE,
        backgroundColor: green[500],
    },
    pendingMarker: {
        ...AVATAR_BASE_STYLE,
        background: 'transparent',
    },
    warningMarker: {
        ...AVATAR_BASE_STYLE,
        backgroundColor: deepOrange[500],
    },
    abortedMarker: {
        ...AVATAR_BASE_STYLE,
        backgroundColor: grey[500],
    },

})

const CLASS_NAMES_BY_STATUS = {
    [StageStatus.SUCCESS]: 'successMarker',
    [StageStatus.IN_PROGRESS]: 'pendingMarker',
    [StageStatus.FAILED]: 'errorMarker',
    [StageStatus.UNSTABLE]: 'warningMarker',
	[StageStatus.ABORTED]: 'abortedMarker',
}

const ICON_COMPONENTS_NAMES_BY_STATUS = {
    [StageStatus.SUCCESS]: CheckIcon,
    [StageStatus.IN_PROGRESS]: CircularProgress,
    [StageStatus.FAILED]: CloseIcon,
    [StageStatus.UNSTABLE]: WarningIcon,
	[StageStatus.ABORTED]: CloseIcon,
}

interface ExecutionStatusAvatarProps {
    stage: Stage
    classes: Record<any, any>
}

const ExecutionStatus = ({ stage, classes }: ExecutionStatusAvatarProps) => {
    const avatarClassName = classes[CLASS_NAMES_BY_STATUS[stage.status]]
    const Icon = ICON_COMPONENTS_NAMES_BY_STATUS[stage.status]
    return (
        <Avatar className={avatarClassName}>
            <Icon className={classes.avatarIcon} size={20} />
        </Avatar>
    )
}

const createDetails = (stage: Stage) => {
    const timeSpan = formatTimeSpan(
        stage.startTimeMillis,
        stage.startTimeMillis + stage.durationMillis
    )
    const duration = formatDurationToMinutes(stage.durationMillis)
    return `${timeSpan} (${duration} min)`
}

function ExecutionStages({ stages }: Props) {
    const classes = useStyles()
    // stages[0].status = 'IN_PROGRESS'

    return (
        <ul className={classes.root}>
            {stages.map(stage => (
                <li className={classes.elementWrapper} key={stage.name}>
                    <ExecutionStatus stage={stage} classes={classes} />

                    <div className={classes.detailsWrapper} key={stage.name}>
                        <span className={classes.elementTitle}>{stage.name}</span>
                        <span className={classes.elementDetails}>{createDetails(stage)}</span>
                    </div>
                </li>
            ))}
        </ul>
    )
}

export default ExecutionStages
