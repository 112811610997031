import Pipeline from './Pipeline'
import Release from './Release'
import Build from './Build'

export enum ArtifactStatus {
    ACTIVE = 'ACTIVE',
    PENDING = 'PENDING',
    ERROR = 'ERROR',
}

export default interface Artifact {
    id: string
    name: string
    sanitizedName: string
    status: ArtifactStatus
    workspaceId: string
    sourceCodeRepositoryUrl: string
    jenkinsUrl: string
    siteUrl: string | null
    gitLabUrl: string | null
    pipelines: Array<Pipeline>
    releases: Array<Release>
    builds: Array<Build>
    created: string
    lastUpdated: string
}
