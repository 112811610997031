import React, { createContext, Reducer, useContext, useReducer } from 'react'
import Workspace from '../../workspaces/data/entities/Workspace'

interface AppState {
    jwtToken: string | null
    currentWorkspace: Workspace | null
}

export const AppStateContext = createContext<AppState>({ jwtToken: null, currentWorkspace: null })

interface Props {
    reducer: Reducer<AppState, any>
    initialState: AppState
}

export const StateProvider: React.FC<Props> = ({ reducer, initialState, children }) => {
    const value: any = useReducer(reducer, initialState)
    return <AppStateContext.Provider value={value}>{children}</AppStateContext.Provider>
}
export const useAppState = () => useContext(AppStateContext) as any
