import SignInError, { SignInErrorType } from '../../signin/data/exceptions/SignInError'
import { TokenStorage } from '../../signin/data/TokenStorage'

export default (tokenStorage: TokenStorage, shouldRedirectWhenAuthFailed = true) => {
    return async (response: Response) => {
        if (!response.ok) {
            if (response.status === 401) {
                tokenStorage.deleteToken()

                if (shouldRedirectWhenAuthFailed) {
                    window.location.href = '/'
                }

                throw new SignInError(SignInErrorType.UNAUTHORIZED)
            }

            throw new SignInError(SignInErrorType.GENERIC)
        }
        return response
    }
}
