import * as React from 'react'
import {green, grey, red, deepOrange} from '@material-ui/core/colors'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Execution, {ExecutionStatus} from '../../data/entities/Execution'
import Avatar from '@material-ui/core/Avatar/Avatar'
import {CircularProgress, Grid, Typography} from '@material-ui/core'
import {formatDateTime} from '../../../common/presentation/formatDateTime'
import {formatDurationToMinutes} from '../../../common/presentation/formatDurationToMinutes'
import ExecutionStages from './ExecutionStages'
import PastExecutions from './PastExecutions'
import WarningIcon from "@material-ui/icons/Warning";

interface Props {
    execution: Execution
    pastExecutions: Array<Execution>
}

const AVATAR_BASE_STYLE = {
    marginRight: 16,
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },

    detailsWrapper: {
        flex: 1,
    },

    errorMarker: {
        ...AVATAR_BASE_STYLE,
        backgroundColor: red[500],
    },
    successMarker: {
        ...AVATAR_BASE_STYLE,
        backgroundColor: green[500],
    },
    pendingMarker: {
        ...AVATAR_BASE_STYLE,
        background: 'transparent',
    },
    warningMarker: {
        ...AVATAR_BASE_STYLE,
        backgroundColor: deepOrange[500],
    },
    abortedMarker: {
        ...AVATAR_BASE_STYLE,
        backgroundColor: grey[500],
    },
    detailText: {
        color: grey[500],
    },
}))

interface ExecutionStatusAvatarProps {
    execution: Execution
    classes: Record<any, any>
}

const createTitle = (execution: Execution) => {
    switch (execution.status) {
        case ExecutionStatus.SUCCESS:
            return `Execution ${execution.name} was successful`
        case ExecutionStatus.IN_PROGRESS:
            return `In progress...`
        case ExecutionStatus.NOT_EXECUTED:
            return `Execution pending.`
        case ExecutionStatus.FAILED:
            return `Execution ${execution.name} failed`
        case ExecutionStatus.ABORTED:
            return `Execution ${execution.name} was aborted`
        case ExecutionStatus.UNSTABLE:
            return `Execution ${execution.name} was unstable`
        default:
            throw Error('Unknown status: ' + execution.status)
    }
}

const createSubTitleTimeSpan = (execution: Execution) => {
    const startTime = formatDateTime(execution.startTimeMillis)
    const endTime = formatDateTime(execution.endTimeMillis)

    switch (execution.status) {
        case ExecutionStatus.SUCCESS:
        case ExecutionStatus.FAILED:
        case ExecutionStatus.ABORTED:
        case ExecutionStatus.UNSTABLE:
        case ExecutionStatus.NOT_EXECUTED:
            return `Ran from ${startTime} to ${endTime}`
        case ExecutionStatus.IN_PROGRESS:
            return `Running since ${startTime}`
        default:
            throw Error('Unknown status: ' + execution.status)
    }
}

const createSubTitleDuration = (execution: Execution) => {
    const durationMinutes = formatDurationToMinutes(execution.durationMillis)

    switch (execution.status) {
        case ExecutionStatus.SUCCESS:
        case ExecutionStatus.FAILED:
        case ExecutionStatus.ABORTED:
        case ExecutionStatus.UNSTABLE:
        case ExecutionStatus.NOT_EXECUTED:
            return `Duration: ${durationMinutes} minutes`
        case ExecutionStatus.IN_PROGRESS:
            return `${durationMinutes} minutes elapsed`
        default:
            throw Error('Unknown status: ' + execution.status)
    }
}

const ExecutionStatusAvatar = ({ execution, classes }: ExecutionStatusAvatarProps) => {
    const avatarClassName = classes[CLASS_NAMES_BY_STATUS[execution.status]]
    const Icon = ICON_COMPONENTS_NAMES_BY_STATUS[execution.status]
    return (
        <Avatar className={avatarClassName}>
            <Icon />
        </Avatar>
    )
}

const CLASS_NAMES_BY_STATUS = {
    SUCCESS: 'successMarker',
    NOT_EXECUTED: 'pendingMarker',
    ABORTED: 'abortedMarker',
    IN_PROGRESS: 'pendingMarker',
    FAILED: 'errorMarker',
    UNSTABLE: 'warningMarker',
}

const ICON_COMPONENTS_NAMES_BY_STATUS = {
    SUCCESS: CheckIcon,
    FAILED: CloseIcon,
    NOT_EXECUTED: CircularProgress,
    ABORTED: CloseIcon,
    IN_PROGRESS: CircularProgress,
    UNSTABLE: WarningIcon,
}

function LatestExecution({ execution, pastExecutions }: Props) {
    const classes = useStyles()
    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <div className={classes.root}>
                    <ExecutionStatusAvatar execution={execution} classes={classes} />
                    <div className={classes.detailsWrapper}>
                        <Typography variant="subtitle1">{createTitle(execution)}</Typography>
                        <Typography variant="body2" className={classes.detailText}>
                            {createSubTitleTimeSpan(execution)}
                            <br />
                            {createSubTitleDuration(execution)}
                        </Typography>

                        <PastExecutions executions={pastExecutions} />
                    </div>
                </div>
            </Grid>

            <Grid item xs={6}>
                <ExecutionStages stages={execution.stages} />
            </Grid>
        </Grid>
    )
}

export default LatestExecution
