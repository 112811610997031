import * as React from 'react'
import { TextField, Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import hintStyle from './hintStyle'

interface Props {
    name: string | null
    onNameChange: (name: string) => void
    disabled: boolean
}

const useStyles = makeStyles({
    hint: {
        ...hintStyle,
    },
})

function BasicsInput({ name, onNameChange, disabled }: Props) {
    const classes = useStyles()
    return (
        <>
            <Typography variant="body2" className={classes.hint}>
                Provide basic information about the workspace.
            </Typography>

            <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Workspace name"
                type="text"
                fullWidth
                autoComplete="off"
                value={name}
                onChange={event => onNameChange(event.currentTarget.value)}
                disabled={disabled}
            />
        </>
    )
}

export default BasicsInput
