export enum SignInErrorType {
    UNAUTHORIZED = 'UNAUTHORIZED',
    GENERIC = 'GENERIC',
}

export default class SignInError {
    type: SignInErrorType

    constructor(type: SignInErrorType) {
        this.type = type
    }
}
