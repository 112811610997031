import * as React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'
import './CircularProgressCentered.scss'

function CircularProgressCentered() {
    return (
        <div className={'circular-progress-centered'}>
            <CircularProgress />
        </div>
    )
}

export default CircularProgressCentered
