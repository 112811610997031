import React from 'react'
import './App.scss'
import createMuiTheme from '@material-ui/core/styles/createTheme'
import { MuiThemeProvider } from '@material-ui/core/styles'
import NavBar from './common/presentation/components/NavBar/NavBar'
import MainContent from './common/presentation/components/MainContent'
import { withRouter } from 'react-router'
import { StateProvider } from './common/presentation/AppStateContext'
import { TokenStorage } from './signin/data/TokenStorage'
import AppActionType from './AppActionType'

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#6cb780',
            contrastText: '#fff',
        },
        secondary: {
            main: '#ffffff',
            contrastText: '#555',
        },
    },
})

const DEFAULT_APP_STATE = {
    jwtToken: null,
    currentWorkspace: null,
}

function App() {
    const tokenStorage = TokenStorage.getInstance()

    const initialUserState = {
        ...DEFAULT_APP_STATE,
        jwtToken: tokenStorage.getToken(),
    }

    const reducer = (state: any, action: any) => {
        switch (action.type) {
            case AppActionType.STORE_SIGN_IN:
                tokenStorage.storeToken(action.token)
                return {
                    ...state,
                    jwtToken: action.token,
                }

            case AppActionType.CLEAR_SIGN_IN:
                tokenStorage.deleteToken()
                // This is a hacky solution. The goal is to logout the user from the IdP
                // To do this we need to trigger the backend and the backend will start
                // a complicated request to the IdP. To simplify things, we just call 
                // jenkins logout request, and we will be completely logged out of the tool
                window.location.href = process.env.REACT_APP_JENKINS_BASE_URL + "/logout"
                // return {
                //     ...DEFAULT_APP_STATE,
                // }

            case AppActionType.USE_WORKSPACE:
                return {
                    ...state,
                    currentWorkspace: action.workspace,
                }

            default:
                throw Error(`Unhandled action of type "${action}"`)
        }
    }

    return (
        <MuiThemeProvider theme={theme}>
            <StateProvider initialState={initialUserState} reducer={reducer}>
                <NavBar />
                <MainContent />
            </StateProvider>
        </MuiThemeProvider>
    )
}

export default withRouter(App)
